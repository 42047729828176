import Icons from "../assets/icons";
import { Routes } from "../routes";

const EN = {
  enums: {
    badges: {
      registration: "Novice",
      login: "Scholar",
      user_rank: "Pursuer",
      add_course_suggestion: "Pioneer",
      add_review: "Reviewer",
      spend_minutes: "Netizen",
      courses_completed: "Keen student",
      courses_completed_time: "Fast track",
      course_qas_joined: "Live action",
      competence_group: "Holistic",
      protege: "Protege",
      correct_answers: "Infallible",
      register_le: "Attendee",
      idp_premature: "Speed run",
      idps: "Career designer",
      send_message: "Messenger",
      forum_post_added: "Wisdom sharer",
      endorsements: "Trendsetter",
      social_stars: "Socialite",
    },
    businessAreas: {
      ct: "Clinical Trials",
      ma: "Market Access",
      med: "Medical Affairs",
      other: "Other",
      pha: "Pharmacovigilance",
      qa: "Quality Assurance",
      ra: "Regulatory Affairs",
    },
    chatbotCourseTypes: {
      0: "PV",
      1: "CT",
      2: "RA",
    },
    chatbotReferenceTypes: {
      3: "Legislation",
      4: "Guidelines",
    },
    competenceLevels: {
      1: "1",
      2: "2",
      3: "3",
    },
    courseAuditActions: {
      0: "Inactivate",
      1: "Bump Version",
      2: "Update",
      3: "Inactivate & Update",
    },
    courseAuditStatuses: {
      0: "To do",
      1: "To be inactivated",
      2: "To be version bumped",
      3: "To be updated",
      4: "To inactive & update",
      5: "In progress",
      6: "Blocked",
      7: "Ready for upload",
      8: "New version created",
      9: "Completed",
    },
    courseReason: {
      0: "CourseQA",
      1: "On demand",
      2: "Face to face",
    },
    educationDegrees: {
      ba: "Bachelor's degree",
      ma: "Master's degree",
      phd: "PhD degree",
      post: "Post Graduate degree",
    },
    educationFields: {
      bio: "Biology",
      biom: "Biomedical Science",
      biotech: "Biotechnology",
      chem: "Chemistry",
      den: "Dentistry",
      med: "Medicine",
      nur: "Nursing",
      oth: "Other",
      pha: "Pharmacy",
    },
    experienceLevels: {
      1: "Beginner",
      2: "Intermediate",
      3: "Advanced",
    },
    fileCategories: {
      0: "Course",
      1: "Learning event",
      2: "Regulatory Intelligence",
    },
    languages: {
      EN: "English",
      PL: "Polish",
    },
    learningAreas: {
      0: "Pharmacovigilance",
      1: "Clinical Trials",
      2: "Regulatory Affairs",
    },
    licensesTypes: {
      0: "Student",
      1: "Admin",
    },
    idpStatus: {
      0: "Created",
      1: "Sent for approval",
      2: "Approved",
      3: "Back for review",
    },
    questionTypes: {
      0: "Single choice",
      1: "Multiple choice",
      2: "Fill the holes",
      3: "Pick valid order",
      4: "Pair up",
    },
    referenceAuditStatuses: {
      0: "To do",
      1: "In progress",
      2: "Blocked",
      3: "Completed",
    },
    referenceTypes: {
      0: "Legislation",
      1: "Guideline",
      2: "Journal article",
      3: "Website",
      4: "Video",
      5: "Presentation",
      6: "Book",
      7: "Other",
    },
    referenceDocumentForms: {
      "Regulation": "Regulation",
      "Directive": "Directive",
      "Decision": "Decision",
      "Other": "Other",
    },
    regions: {
      "EU": "European Union",
      "PL": "Poland",
      "WW": "Worldwide",
    },
    searchDescription: {
      0: "Course",
      1: "Learning Event",
      2: "Competence",
      3: "Job Position",
      4: "Career Path",
      5: "Instructor",
      6: "Regulatory Intelligence",
    },
    testProblemType: {
      0: "Single choice",
      1: "Multiple choice",
      2: "Fill the holes",
      3: "Pick valid order",
      4: "Pair up",
    },
    verificationStatuses: {
      0: "Not verified",
      1: "Outdated",
      2: "Verified",
    },
    verificationTypes: {
      0: "Verified after course",
      1: "Verified by test",
      2: "Verified by manager",
    },
    visibility: {
      0: "Private",
      1: "Team",
      2: "Organization",
      3: "Public",
    },
    weekDays: [
      {
        id: 0,
        label: "Sunday",
      },
      {
        id: 1,
        label: "Monday",
      },
      {
        id: 2,
        label: "Tuesday",
      },
      {
        id: 3,
        label: "Wednesday",
      },
      {
        id: 4,
        label: "Thursday",
      },
      {
        id: 5,
        label: "Friday",
      },
      {
        id: 6,
        label: "Saturday",
      },
    ],
    yesNo: {
      1: "Yes",
      0: "No",
    }
  },
  labels: {
    about: "About",
    aboutCourse: "About course",
    aboutUs: "About us",
    accept: "Accept",
    acceptSmall: "accept",
    acceptance: "Acceptance",
    actionRequired: "Action required",
    actions: "Actions",
    actionsSummary: "Actions summary",
    active: "Active",
    activeCourses: "Active courses",
    activeLicenses: "Active Licenses",
    activityAndSocialBadges: "Activity & social badges",
    activityBadges: "Activity badges",
    actualEnding: "Actual ending",
    actualStart: "Actual start",
    add: "Add",
    addAllFiltered: "Add all filtered",
    addCareerPath: "Add career path",
    addEndorsment: "Add endorsement",
    addInvoice: "Add invoice",
    addJobPosition: "Add Job Position",
    addLicense: "Add license",
    addMore: "Add more",
    addReview: "Add review",
    addReward: "Add reward",
    addSection: "Add section",
    addTeam: "Add team",
    addToCalendar: "Add to calendar",
    addToPlan: "Add to plan",
    addToWorkgroup: "Add to workgroup",
    addUnit: "Add unit",
    addWorkgroup: "Add workgroup",
    added: "Added",
    additionalDetails: "Additional details",
    additionalMaterials: "Additional materials",
    addittionalResources: "Additional resources",
    address: "Address",
    administrative: "Administrative",
    afterCourse: "After course",
    agenda: "Agenda",
    agreement: "Agreement",
    aidify: "AIDIFY",
    aidifyVersion: "AIDIFY version",
    allTime: "All time",
    allowDownload: "Allow Download",
    alm: "ALM",
    almViewership: "ALM viewership",
    amount: "Amount",
    andChecking: "and checking",
    answer: "Answer",
    answerGiven: "Answer given",
    answeredCorrectly: "Answered correctly",
    answers: "Answers",
    answersGiven: "Answers given",
    anyPerson: "Any person",
    approved: "Approved",
    aproximateSavingsForOrganization: "Aproximate savings<br/>for organization",
    aproximateSavingsForOrganizations: "Aproximate savings<br/>for organizations",
    archivedOn: "Archived on",
    areYueSureYouWantToAssignTo: "Are yue sure you want to sign up for",
    askQuestion: "Ask question",
    assignCourses: "Assign courses",
    assignToCompetition: "Assign to competition",
    assignUser: "Assign user",
    assignUsers: "Assign users",
    assigned: "Assigned",
    assignedCourse: "Assigned course",
    assignedEmployee: "Assigned employee",
    assignedOn: "Assigned on",
    assigntoCourse: "Assign to course",
    at: "at",
    attachFile: "Attach file",
    attachedFile: "Attached file",
    auditDate: "Audit date",
    auditProof: "Audit proof",
    author: "Author",
    authors: "Author(s)",
    availableFrom: "Available from",
    averageBadgeLevels: "Average sum of obtained badge levels",
    averageCourseRating: "Average course rating",
    averageInstrucorRating: "Average instructor rating",
    averageNumberOfCoursesByInstructors: "Average number of courses taught by instructors",
    averagePrice: "Average price",
    averageRating: "Average rating",
    averageTestResult: "Average test result",
    averageTimeSpendStudent: "Average time spent on platform by student",
    averageTimeSpentOnPlatformByEmployee: "Average time spent<br/>on platform by employee",
    averageTimeSpentOnPlatformByUser: "Average time spent<br/>on platform by user",
    averageWatchingTime: "Average watching time",
    averageWatchingTimeComparedToDuration: "Average watching time compared to duration",
    back: "Back",
    backForReview: "Back for review",
    backToCurrentPlan: " Back to current plan",
    badge: "Badge",
    badgeLevel: "Badge level",
    badges: "Badges",
    badgesLevelsSum: "Badges levels sum",
    badgesSummary: "Badges summary",
    bonusPoints: "Bonus points",
    browse: "browse",
    businessArea: "Business Area",
    buy: "Buy",
    buyIt: "Buy it!",
    by: "By",
    byAttendingThisCourse: "By attending this course, you will learn:",
    byManager: "By manager",
    byTest: "By test",
    cancel: "Cancel",
    cancelSmall: "cancel",
    career: "Career",
    careerPath: "Career path",
    careerPathCompetences: "Career path competences",
    careerPathDetails: "Career path details",
    careerPaths: "Career paths",
    category: "Category",
    certificate: "certificate",
    certificates: "Certificates",
    changeStatus: "Change status",
    chat: "Chat",
    chatMessage: "Chat message",
    chatName: "Chat name",
    choiceType: "Chice type",
    churnPredictionReport: "Churn prediction report",
    cited: "Cited",
    city: "City",
    claimed: "Claimed",
    clickHere: "Click here",
    clickToCreateAHole: "Click to create a hole.",
    clone: "Clone",
    close: "Close",
    closeViewAs: `Close "View as" tool`,
    closingLeads: "Closing leads",
    collected: "Collected",
    collection: "Collection",
    collections: "Collections",
    commaSeparated: "Comma separated",
    comment: "Comment",
    company: "Company",
    companyName: "Company name",
    competence: "Competence",
    competenceDetails: "Competence details",
    competenceDoesntHaveGivenLevel: "This competence doesn't have given level",
    competenceLevel: "Competence level",
    competenceList: "Competence list",
    competenceObtainingProgress: "Competence obtaining progress",
    competenceTest: "Competence test",
    competenceTestReport: "Competence test report",
    competences: "Competences",
    competencesAssignedToYou: "Competences assigned to you",
    competencesCovered: "Competences covered by this course",
    competencesOnPlatform: "Number of competences on the platform",
    competitions: "Competitions",
    complementaryCompetence: "Complementary competence",
    complementaryCompetences: "Complementary competences",
    completed: "Completed",
    completedCourses: "Completed courses",
    completedDate: "Completed date",
    completedTime: "Completed time",
    completedTimeAverageTime: "Completed time compared to average time",
    completedTimeComparedDur: "Completed time compared to duration",
    confirm: "Confirm",
    confirmation: "Confirmation",
    continueWatching: "Continue watching",
    conspect: "Conspect",
    cookiesPolicy: "Cookies Policy",
    copyCourse: "Copy course",
    correctAnswersGiven: "Correct answers given",
    cost: "Cost",
    count: "Count",
    country: "Country",
    countryOfWorkplace: "Country of workplace",
    course: "Course",
    courseAssigment: "Course assigment",
    courseAudit: "Course audit",
    courseAudits: "Course audits",
    courseCompletionThatIecommended: "Course completion that I recommended",
    courseDeadline: "Course deadline",
    courseDetails: "Course details",
    courseId: "Course ID",
    courseInProgress: "Course In progress",
    courseLevel: "Course level",
    courseList: "Course list",
    courseName: "Course name",
    coursePart: "Course part",
    courseProgress: "Course progress",
    courseRecommendations: "Course recommendations",
    courseReview: "Course review",
    courseReviews: "Course reviews",
    courseUnit: "Course unit",
    courseView: "Course view",
    courses: "Courses",
    coursesAttendantsNumber: "Courses attendants number",
    coursesCompletionPercentage: "Courses completion percentage",
    coursesFastFiltersValues: { continue: "Continue watching", development: "Focus on your development", included: "Included in your license", new: "New on platform", popular: "Most popular ones", recommended: "Recommended for you by AIDIFY", urgent: "Most urgent courses" },
    coursesFinishPercentage: "Courses finish percentage",
    coursesOnPlatform: "Number of courses on the platform",
    coursesPassPercentage: "Course pass percentage",
    coursesPassingPercentage: "Courses passing percentage",
    coursesRealisationTimeComparedExpectTime: "Courses realisation time compared to expected time",
    coursesRealisationTimeComparedOtherStudents: "Courses realisation time compared to other students",
    coursesStatistics: "Courses statistics",
    cpa: "CPA",
    cpaViewership: "CPA viewership",
    createNewOrganization: "Create new organization",
    created: "Created",
    createdAt: "Created at",
    createdBy: "Created by",
    createdOn: "Created on",
    current: "Current",
    currentCompetitions: "Current competitions",
    currentLevel: "Current level",
    currentPosition: "Current position",
    dashboard: "Dashboard",
    database: "Database",
    date: "Date",
    dateOfCitation: "Date of citation",
    dateOfEvent: "Date of event",
    dateOfIssue: "Date of issue",
    dateOfPublication: "Date of publication",
    days: "Days",
    deadline: "Deadline",
    deadlineDate: "Deadline date",
    decline: "Decline",
    degree: "Degree",
    delete: "Delete",
    deleteLicense: "Delete license",
    description: "Description",
    desiredFuturePosition: "Desired future position",
    details: "Details",
    directUrl: "Direct URL",
    disable: "Disable",
    disabled: "Disabled",
    displayName: "Display name",
    documentForm: "Document form",
    documentFormText: "Document form (text)",
    documentNumber: "Document number",
    doi: "DOI (Digital Object Identification)",
    done: "Done",
    download: "Download",
    duration: "Duration",
    edit: "Edit",
    editCareerPath: "Edit career path",
    editOrganization: "Edit organization",
    editReview: "Edit review",
    editText: "Edit text",
    editWorkgroup: "Edit workgroup",
    edition: "Edition",
    education: "Education",
    educationEntry: "Education entry",
    effectiveDate: "Effective date",
    element: "Element",
    email: "E-mail",
    employeeName: "Employee name",
    employeeStatisticsInCourses: "Employee statistics in courses",
    employees: "Employees",
    enable: "Enable",
    enabled: "Enabled",
    end: "End",
    endDayOfLearning: "End day of learning",
    endorsement: "Endorsement",
    error: "Error",
    estimatedIdpFinishDone: "Estimated IDP finish done",
    estimatedTime: "Estimated time",
    eventLocation: "Event location",
    eventType: "Event type",
    expLevel: "Expierence level",
    experienceLevel: "Experience level",
    expires: "Expires",
    expiryDate: "Expiry date",
    export: "Export",
    extension: "Extension",
    external: "External",
    externalURL: "External URL",
    fastFilters: "Fast filters",
    field: "Field",
    file: "File",
    fileDownloadable: "File downloadable?",
    fileName: "File name",
    fileType: "File type",
    filename: "Filename",
    files: "Files",
    fill: "Fill",
    filter: "Filter",
    filterResults: "Filter results",
    finalizeAudit: "Finalize audit",
    finishPercentage: "Finish percentage",
    firstName: "First name",
    for: "For",
    forgotPassword: "Forgot password",
    form: "Form",
    freeDays: "Number of free days",
    freemium: "Freemium",
    from: "From",
    gift: "Gift",
    giftDetails: "Gift details",
    giftShop: "Gift shop",
    gifts: "Gifts",
    global: "Global",
    goToIdp: "Go to IDP",
    goToOrganizationLicenses: "Go to Organization Licenses",
    goToPage: "Go to page",
    goToShop: "Go to shop",
    goal: "Goal",
    goalLevel: "Goal level",
    group: "Group",
    groupChatsString: "Group Chats",
    hasExpired: "has expired",
    here: "Here",
    history: "History",
    homepage: "Homepage",
    hour: "hour",
    hourAgo: "hour ago",
    hourShort: "h",
    hours: "hours",
    hoursAgo: "hours ago",
    hoursLeft: "hours left",
    iAcceptThe: "I accept the",
    iAgreeToThe: "I agree to the",
    id: "ID",
    idpHistory: "IDP History",
    idpProgress: "IDP Progress",
    idpStatus: "IDP Status",
    image: "Image",
    importance: "Importance",
    inCurrentUnit: "In current unit",
    inProgress: "In progress",
    inSeconds: "in seconds",
    inYourJobPosition: "In your job position",
    inactive: "Inactive",
    includedContent: "Included content",
    income: "Income",
    incomingLeads: "Incoming leads",
    indirectUrl: "Indirect URL",
    individualDevelopmentPlan: "Individual Development Plan",
    informOrganization: "Inform organization",
    information: "Information",
    instructor: "Instructor",
    instructorDashboard: "Instructor dashboard",
    instructorDetails: "Instructor details",
    instructorLinkedInProfile: "Instructor LinkedIn Profile",
    instructorRating: "Instructor rating",
    instructorReview: "Instructor review",
    instructorReviews: "Instructor reviews",
    instructorStatistics: "Instructor statistics",
    instructors: "Instructors",
    instructorsOnPlatform: "Number of instructors on the platform",
    internal: "Internal",
    internalCareerPath: "Internal career path",
    internalTraining: "Internal training",
    internet: "Internet",
    inviteNewEmployee: "Invite new employee",
    inviteNewUser: "Invite new user",
    invoice: "Invoice",
    invoiceApproved: "Invoice approved",
    invoiceApprovedText: "Invoice was approved succesfully.",
    invoiceDisapproved: "Successful invoice revocation.",
    invoiceNumber: "Invoice number",
    invoices: "Invoices",
    isActive: "Is active?",
    isFreemium: "Is freemium",
    isRelatedCoursesAuditRequired: "Is related courses audit required?",
    isValid: "Is valid",
    issuedCertificates: "issued certificates",
    jobExperience: "Job experience",
    jobPosition: "Job position",
    jobPositionChart: "Job position chart",
    jobPositionCompetence: "Job position competence",
    jobPositionDetails: "Job position details",
    jobPositions: "Job positions",
    jobTitle: "Job Title",
    journalName: "Journal name",
    keyCompetence: "Key competence",
    keyCompetences: "Key competences",
    label: "Contetnt",
    language: "Language",
    lastAcitivity: "Last activity",
    lastName: "Last name",
    lastWinner: "Last winner",
    learners: "learners",
    learning: "Learning",
    learningArea: "Learning area",
    learningBadges: "Learning badges",
    learningEvent: "Learning event",
    learningEventDetails: "Learning event details",
    learningEvents: "Learning events",
    learningGoals: "Learning goals",
    learningOutcomes: "Learning outcomes",
    learningPlan: "Learning plan",
    learningReport: "Learning report",
    lecturer: "Lecturer",
    left: "left",
    level: "Level",
    levelOfKnowledge: "Level of knowledge",
    license: "License",
    licenseAssigned: "License Assigned",
    licenseControl: "License control",
    licenseExpiryDate: "License expiry date",
    licenseNumber: "License number",
    licensePrice: "License price",
    licenseType: "License type",
    licenses: "Licenses",
    licensesAmountInTime: "Licenses amount in time",
    like: "Like",
    liked: "Liked",
    likes: "Likes amount",
    link: "Link",
    linkedElName: "Linked el. name",
    linkedElement: "Linked element",
    linkedElementName: "Linked el. name",
    linkedin: "LinkedIn",
    linkedinLink: "Linkedin link",
    loading: "Loading...",
    location: "Location",
    logout: "Logout",
    lottery: "lottery",
    lotteryTicket: "Lottery ticket",
    manager: "Manager",
    managerChange: "Manager change",
    managerComments: "Manager comments",
    manualVerification: "Manual verification",
    markAsBlocked: "Mark as blocked",
    markAsPaid: "Mark as paid",
    markAsReadyForUpload: "Mark as ready for upload",
    markAsUnpaid: "Mark as unpaid",
    marketplace: "Marketplace",
    match: "match",
    members: "Members",
    menu: "Menu",
    min3characters: "Type minimum 3 characters.",
    min: "min",
    minAgo: "min ago",
    miniSubscription: "Mini subscription",
    minsAgo: "mins ago",
    minute: "minute",
    minutes: "minutes",
    minutesLeft: "minutes left",
    minutesPerDay: "Number of minutes per day",
    minutesPlayed: "Minutes Played",
    minutesPlayedOutOfTotal: "Minutes played out of total",
    mobileApp: "Mobile App",
    modified: "Modified",
    moduleString: "Module",
    monetization: "Monetization",
    monthly: "Monthly",
    more: "More",
    moveDown: "Move down",
    moveUp: "Move up",
    myCompetition: "My competition",
    name: "Name",
    negative: "Negative",
    new: "New",
    newArticleRegInt: "New article in Regulatory Intelligence",
    newChat: "New Chat",
    newCompetition: "New competition",
    newCourse: "New course",
    newCourseIrequested: "New course that I requested",
    newLearningEvents: "New learning events",
    newQa: "New qa",
    newReplyonObservewThread: "New reply on observew thread",
    newStatus: "New status",
    newVersion: "New version",
    newVersionCreated: "New version created",
    newWorkgroup: "New workgroup",
    next: "Next",
    nextAuditDate: "Next audit date",
    nextDraw: "Next draw",
    nextJobPosition: "Next Job Position",
    nextPosition: "Next Position",
    no: "No",
    noComments: "No comments",
    noData: "No data",
    noNotifications: "No notifications",
    noOfEmployees: "Number of employees",
    noOfLicenses: "Number of licenses",
    noOfOrganizationMembers: "No of organization members",
    noOfStudentsHaving: "Numbers of students having it",
    noOfTeamMembers: "No of team members",
    noOfTickets: "Number of tickets",
    noPlan: "No plan",
    noResults: "No results",
    none: "None",
    notArchived: "Not archived",
    notStarted: "Not started",
    notVerified: "Not verified",
    notification: "Notification",
    notifications: "Notifications",
    notificationsSettings: "Notifications settings",
    number: "Number",
    numberOfParticipants: "Number of participants",
    numbersOfCompetences: "Numbers of competences",
    obtained: "Obtained",
    obtainedCertificates: "Obtained certificates",
    of: "of",
    ok: "Ok",
    older: "Older",
    on: "on",
    oneTimePassword: "One Time Password",
    ongoing: "Ongoing",
    onPage: "on page",
    order: "Order",
    orgMembersOnThisPath: "Organization members<br/>on this path",
    orgMembersOnThisPosition: "Organization members<br/>on this job position",
    organization: "Organization",
    organizationInternal: "Organization internal",
    organizationMembers: "Organization members",
    organizations: "Organizations",
    organizer: "Organizer",
    other: "Other",
    otherInformation: "Other information",
    ourMission: "Our mission",
    overall: "Overall",
    overdue: "Overdue ",
    overdueCourses: "Overdue courses",
    page: "Page",
    paid: "Paid",
    partOfTheCourse: "Part of the course",
    passPercentage: "Pass percentage",
    passPercentageByCourse: "Pass percentage by course",
    passPercentageByTest: "Pass percentage by test",
    password: "Password",
    paymentDeadline: "Payment deadline",
    paymentStatus: "Payment status",
    payments: "Payments",
    pdf: "PDF",
    period: "Period",
    personInMyOrganization: "Person in my organization",
    personInMyTeam: "Person in my team",
    personal: "Personal",
    personalData: "personal data",
    personalInterest: "Personal interest",
    pharma: "Pharma",
    phone: "Phone",
    phoneNumber: "Phone number",
    photo: "photo",
    placeOfPublication: "Place of publication ",
    plannedEnding: "Planned ending",
    plannedNoOfDays: "Planned no of days",
    plannedNoOfMinutes: "Planned no of minutes",
    plannedStart: "Planned start",
    platformDashboard: "Platform dashboard",
    play: "Play",
    pln: "PLN",
    points: "Points",
    popularity: "Popularity",
    position: "Position",
    positive: "Positive",
    postalCode: "Postal code",
    practicalTask: "Practical task",
    practiceActivities: "Practice Activities",
    predictiveNewMaterial: "Predictice new material analysis",
    pressEnterToSend: "Press enter to send",
    preview: "Preview",
    previous: "Previous",
    previousVersion: "Previous version",
    previously: "Previously",
    price: "Price",
    priceEach: "Price each",
    priv: "Private",
    privacyPolicy: "Privacy Policy",
    privacySettings: "Privacy settings",
    privacySettingsTableHeaders: { manager: "MANAGER", team: "TEAM", organization: "ORGANIZATION", public: "PUBLIC" },
    profession: "Profession",
    profile: "Profile",
    profilePicture: "Profile picture",
    progress: "Progress",
    provideAnswer: "Provide answer",
    provideUrl: "Provide URL",
    provideVideoURL: "Provide Video URL",
    publisher: "Publisher",
    qrCode: "QR Code",
    quarterly: "Quarterly",
    question: "Question",
    questionType: "Question type",
    questions: "Questions",
    rank: "Rank",
    rating: "Rating",
    read: "Read",
    received: "Received",
    recommend: "Recommend",
    recommended: "Recommended",
    recommendedNoOfMinutes: "Recommended no of minutes",
    recommmendCourse: "Recommend course",
    reference: "Reference",
    referenceAudit: "Reference audit",
    referenceAudits: "Reference audits",
    referenceChecks: { 1: "Expires in < 1 week", 2: "Expires in < 3 months", 3: "Outdated", 4: "Link broken", 5: "Next audit date exceeded", 6: "Audit in progress" },
    referenceControl: "Reference control",
    referenceDetails: "Reference details",
    referenceId: "Reference ID",
    references: "References",
    region: "Region",
    register: "Register",
    registration: "Registration",
    regulatoryDocument: "Regulatory document",
    regulatoryIntelligenceArticle: "Regulatory Intelligence article",
    regulatoryIntelligenceArticleDetails: "Regulatory Intelligence article details",
    regulatoryIntelligenceArticles: "Regulatory Intelligence articles",
    relatedCareerPaths: "Related career paths",
    relatedCompetences: "Related competences",
    relatedCourse: "Related course",
    relatedCourses: "Related courses",
    relatedCoursesAudits: "Related courses audits",
    relatedJobPositions: "Related job positions",
    relatedReferenceAudit: "Related reference audit",
    released: "Released",
    remainningInIdp: "Remainning in IDP",
    reminderDays: "Reminder days",
    removeCourseContentText: "Are you sure you want remove this course content?",
    repeatPassword: "Repeat password",
    repeatSection: "Repeat section",
    repeatUnit: "Repeat unit",
    reportContext: "Report context",
    reportDate: "Report date",
    reportProblem: "Report problem",
    required: "Required",
    requiredActionDetails: "Required action details",
    requiredPeriodicReconciliation: "Is periodic reconciliation required?",
    resetFilters: "Reset filters",
    resource: "Resource",
    restoreDefaults: "Restore defaults",
    result: "Result",
    results: "Results",
    revenue: "Revenue",
    review: "Review",
    reviewers: "reviewers",
    reviews: "reviews",
    revisionSummary: "Revision summary",
    reward: "Reward",
    rewardsAndEndorsements: "Rewards & endorsments",
    runTest: "Run test",
    salesOverview: "Sales Overview",
    save: "Save",
    saveAndSendForApproval: "Save & send for approval",
    saveCourse: "Save course",
    school: "School",
    search: "Search",
    searchForAnything: "Search for anything",
    searchPopularity: "Search popularity",
    searchResults: "Search results",
    sec: "sec",
    secAgo: "sec ago",
    secondName: "Second name",
    secsAgo: "secs ago",
    section: "Section",
    sections: "Sections",
    seeAllResults: "See all results",
    selecCourses: "Select courses",
    select: "Select",
    selectAll: "Select All",
    selectAvailableLicense: `Find available license on the list and click "Invite" button on the right side.`,
    selectFreeDays: "Select free days",
    selectFreeDaysInCalendar: "Select free days in calendar",
    selectJobPosition: "Select job position",
    selectUser: "Select user",
    selectUserToAssignCourse: "Select users to assign this course",
    selectUsers: "Select users",
    selectWorkgroup: "Select workgroup",
    selectWorkgroupFor: "Select workgroup for",
    send: "Send",
    sendMessage: "Send message",
    sendRequest: "Send request",
    sentForApproval: "Sent for approval",
    setDeadline: "Set deadline",
    setNewPassword: "Set new password",
    setRequiredAction: "Set required action",
    settings: "Settings",
    share: "Share",
    shareWith: "Share with",
    shortDescription: "Short description",
    shouldBeModified: "Content has to be modified",
    showAll: "Show All",
    showAsLink: "Show as link",
    showMoreComments: "Show more comments",
    signIn: "Sign in",
    signUp: "Sign up",
    signUpForTheCompetition: "Sign up for the competition",
    signUpToStartProgressing: "Sign up to start progressing",
    size: "Size",
    socialBadges: "Social badges",
    socialStars: "Social stars",
    startCourse: "Start course",
    startOfLearning: "Start of learning",
    startUpdating: "Start updating",
    startedUnfinishedCourse: "Started unfinished course",
    statistics: "Statistics",
    status: "Status",
    street: "Street",
    student: "Student",
    studentCompetences: "Student competences",
    studentLearningReport: "Student learning report",
    students: "Students",
    studyDays: "Number of days of study",
    subject: "Subject",
    successfullyBought: "Successfully bought",
    suggestNewCourse: "Suggest new course",
    sum: "Sum",
    summary: "SUMMARY",
    team: "Team",
    teamList: "Team list",
    teamMember: "Team member",
    teamMembers: "Team members",
    teamMembersOnThisPosition: "Team members on this job position",
    teamMemnersOnThisPath: "Team members on this path",
    teaser: "Teaser",
    termsAndConditions: "Terms and Conditions",
    test: { paragraph1: "You have answered", paragraph2: "questions out of", paragraph3: "Are you sure you want to end your test?" },
    testAttemps: "Test attemps",
    testResoultsComparedToOrtger: "Test results compared to other students",
    thereAreNoResults: "There are no results for searched phrase",
    theseWorkgroup: "these workgroup",
    thisFieldIsRequired: "This field is required",
    thisIsNoMember: "There is no members in workgroup",
    thisMemberFromTeam: "this member from the team",
    thisMonth: "this month",
    thisUserfromWorkgroup: "this user from the workgroup",
    tickets: "Tickets",
    timeCompetence: "Average time needed to obtain one competence",
    timeSpentOnPlatformByEmployees: "Total time spent<br/>on platform by employees",
    timeSpentOnPlatformByUsers: "Total time spent<br/>on platform by users",
    title: "Title",
    to: "To",
    topicString: "Topic",
    total: "Total",
    totalAssignedCompetences: "Total assigned competences to users",
    totalAssignedCourses: "Total assigned courses",
    totalCoursesHours: "Total courses hours",
    totalCoursesWatched: "Total courses watched hours",
    totalDuration: "Total duration of content",
    totalMinutesPlayed: "Total minutes played",
    totalRevenue: "Total revenue",
    totalTimeSpent: "Total time spent",
    totalTimeWatching: "Total time watching",
    totalViewership: "Total viewership",
    tryAgain: "Reset & Try again",
    twoFactorAuthentication: "Two-factor authentication",
    type: "Type",
    typeInQuestionAndSelect: "Type in question and select words to create answers",
    typeSomething: "Type Something...",
    unassignLicence: "Unassign licence",
    unassigned: "Unassigned",
    unit: "Unit",
    unitData: "Unit data",
    units: "Units",
    unitsCount: "Units count",
    unlike: "Unlike",
    unpaid: "Unpaid",
    unread: "Unread",
    upToDate: "Up to date",
    upcommingLearningEvents: "UPCOMING LEARNING EVENTS",
    uploadFile: "Upload File",
    uploadPhotoFromPC: "Upload photo from your computer",
    urgent: "Most urgent courses",
    urlToReferenceFile: "URL to reference file",
    usefulDocument: "Useful document",
    user: "User",
    userCreatedCompetences: "User created competences",
    userRank: "User rank",
    userStatististics: "User statistics",
    users: "Users",
    valid: "Valid",
    verificationByTest: "Verification by test",
    verified: "Verified",
    verifiedCompetences: "Verified competences",
    version: "Version",
    video: "Video",
    videoViewership: "Video viewership",
    view: "View",
    viewLearningReport: "View learning report",
    viewLinkedInProfile: "View LinkedIn profile",
    viewMore: "View more",
    viewProfession: "View profession",
    viewProfileAs: "View profile as",
    viewStatisticsFor: "View statistics for",
    viewership: "Viewership",
    visibility: "Visibility",
    volumePageRange: "Volume (Issue): page range",
    warning: "Warning!",
    webApp: "Web App",
    welcomeToAidify: "Welcome to AIDIFY!",
    whatIsAidify: "What is AIDIFY?",
    whyThisCourseShouldBeOnThePlatform: "Why this course should be on the platform",
    willExpire: "will expire",
    workgroup: "Workgroup",
    workgroupDetails: "Workgroup details",
    workgroupMember: "Workgroup member",
    workgroups: "Workgroups",
    years: "Years",
    yes: "Yes",
    you: "You",
    youHaveOverduePayment: "You have overdue payments",
    yourAssignments: "Your assignments",
    yourAssignmentsValues: { assignedAndVerified: "Assigned and verified", assignedNotVerified: "Assigned but not verified", notAssigned: "Not assigned" },
    yourBonusPoints: "Your bonus points",
    yourComment: "Your comment",
    yourQuestion: "Your question",
    yourRate: "Your rate",
    yourReview: "Your review",
  },
  messages: {
    addToCalendar: "Do you want to add this event to your calendar?",
    addMfa: "Add an extra layer of security to your account by requiring access to your phone when you log in",
    addMfaStep1: "Scan the below QR code using a one-time passcode generator application.",
    addMfaStep2: "Confirm that your one-time passcode generator application is working fine by entering a generated code below.",
    alreadySaveCourse: "You already saved this course!",
    anyComments: "Do you have any comments?",
    anyQuestion: "Do you have questions? Ask below!",
    areYouSureDelete: "Are you sure you want to delete ",
    areYouSureYouWantToBuy: "Are you sure you want to buy",
    areYouSureYouWantToBuyTickets: "Are you sure you want to buy tickets",
    areYouSureYouWantToConfirm: "Are you sure you want to confirm collection of the reward?",
    areYouSureYouWantToCopyAllUnModifiedUnits: "Are you sure you want to copy all unmodified course units to new version?",
    areYouSureYouWantToDisableMfa: "Are you sure you want to disable two-factor authentication?",
    areYouSureYouWantToEnroll: "Are you sure you want to enroll in this course? This action will affect your mini-subscription limit!",
    areYouSureYouWantToFinalizeCourseAudit: "Are you sure you want to finalize audit? Audit will be marked as completed, new course (if was created) will be activated and the old one deactivated.",
    areYouSureYouWantToFinalizeReferenceAudit: "Are you sure you want to finalize audit? Audit will be marked as completed, new reference will be activated and the old one deactivated.",
    areYouSureYouWantToMarkThisAuditAsBlocked: "Are you sure you want to mark this audit as blocked?",
    areYouSureYouWantToMarkThisAuditAsNotBlocked: "Are you sure you want to mark this audit as NOT blocked?",
    areYouSureYouWantToMarkThisAuditAsReadyForUpload: "Are you sure you want to mark this audit as ready for uploading content?",
    areYouSureYouWantToSendLearningPlan: "Are you sure you want to save this learning plan and send it to your manager for approval?",
    areYouSureYouWantToSetRequiredAction: "Are you sure you want to set selected required action? This will determine future steps of audit process.",
    areYouSureYouWantToStartCourseUpdateProcess: "Are you sure you want to start course update process?",
    areYouSureYouWantToUnassign: "Are you sure you want to unassign",
    assignToWorkgroup: "Assing user to your workgroup",
    attachedFileIncorrectType: "The attached file type is not allowed",
    chatbot: {
      changeOrAsk: "You can change it or just ask next question.",
      courseCheckMore: "Find out more by signing up for the course",
      currentContext: "Currently selected context is",
      disclaimer: "I will do my best to help you, however, nobody is perfect, and I may make mistakes. Consider verifying important information.",
      firstSelectContext: "First, please select the context of your question. I can provide answers based on:<ul style='padding-left: 15px; margin: 5px auto;'>" +
        "<li>AIDIFY courses content within the specified learning area</li>" +
        "<li>Legislation relevant to the courses.</li>" +
        "<li>Applicable guidelines upon which the courses were crea</li></ul>",
      noContext: "I'm sorry, but I'm not able to help you, if I don't know the context of your question. Please select the context first.",
      referenceCheckMore: "You can find out more",
      selectedContext: "You have selected the context of",
      typeQuestion: "Please type in your question.",
      welcome: "Hi! I'm your AIDIFY AI Assistant.",
    },
    chooseExistingConversation: "Choose existing conversation or create new one",
    competencesSuccessfullySaved: "Your competences has been successfully saved",
    confirmationRequired: "Confirmation is required",
    currentQuarterRevenue: "Revenue for the current quarter will be calculated after its closure.",
    createIDP: "Create an Individual Development Plan now!",
    createJobPosition: "Here you can create Job Positions Chart.",
    dataDoesNotExist: "Data for selected parameters does not exist.",
    doYouWantControlDevelopment: "Do you want to have better control on your development?",
    emailSendResetPassword: "An email has been sent to this email if an account is registered",
    enrollOnlyForStudent: "Viewing the course content is available only in Student context",
    enrollToActive: "Enrolling to inactive courses is forbidden",
    enrollWithLicence: "Sorry, your licence does not include this course",
    enterEmailToReset: "Enter your e-mail address to reset your password",
    fieldRequired: "This field is required",
    finishCourse: "Finish this course to obtain",
    goBack: "Go back »",
    incorrectPasswordorEmail: "Incorrect password or email",
    informOrganization: "Do you want to inform your organization administrator about expiring license?",
    invalidDate: "Invalid date",
    invalidEmail: "Invalid e-mail",
    isRequired: "is required.",
    isRequiredWhenAction: "This field is required when you pick action.",
    markPaid: "Do you really want to mark this invoice as PAID?",
    markUnpaid: "Do you really want to mark this invoice as UNPAID?",
    messagesNotFound: "Messages not found",
    minimumPasswordLength: "Minimum length of this field must be equal or greater than 8 symbols",
    mustMatchPassword: "Repeated password must match the previous entry.",
    newAuditForbidden: "You cannot create a new audit of reference if the previous one has not been completed",
    newCourseIdea: "Got an idea for a new course?",
    noCompetences: "There are no competences to verify in step 3. We can finish registration process now.",
    noIdpPlan: "You didn’t define Individual Development Plan yet",
    noJobPositionSelected: "It seems you didn't select any job position, so we won't be able to use our algorithms to help you in selecting courses most suitable for you. Do you want to proceed anyway?",
    noLinkedinProfile: "Add your LinkedIn profile first!",
    noMoreAvailableCoursesInMiniSubscription: "You have reached the limit of available courses included in your mini-subscription license.",
    noPositionYet: "There are no job positions in this career path yet.",
    notEnoughCompetences: "You should select at least 5 competencies to take full advantage of the potential of the platform and AI algorithms. Are you sure you want to continue without it?",
    notSelectedFiles: "You have not selected any files yet",
    passwordChanged: "Your password has been successfully changed, you can login now.",
    passwordRequired: "Password is required",
    pleaseGoToShop: "Please proceed to our online shop to get the access to this course.",
    removeProgress: "Since you have failed too many times, this operation will also remove watching progress from following units",
    resetCourse: "Are you sure you want to reset your test results to try again?",
    savedCourse: "You have saved this course.",
    sendQuestion: "You have sent your question.",
    sentComment: "Your comment has been sent. We will respond to it as soon as possible.",
    serverError: "Aidify servers have encountered an error. Please contact with administrator or try again later.",
    suggestionSentSuccesfully: "Your suggestion has been successfully sent",
    thisActionCannotBeUndone: "This action cannot be undone!",
    useSection: `Use "Add more" section to add your first competence.`,
    useSuggestionForm: "Use our suggestion form to submit a request!",
    usersSelectedToAssignForWorkgroup: "Users selected to assign for workgroup:",
    verifyCourse: "Are you ready to run test and verify what you've learned during this course?",
    verifyManager: "Do you really want to manually confirm this competence for selected used?",
    verifyManual: "Do you want to run test to manually verify this competence?",
    verifyTest: "We need to verify your competences by running a test. Please confirm which competences you want to verify:",
    waitFewMoments: "You sent too many requests within a short period of time, please try again later.",
    warning: "WARNING!",
    yourAccessToCourseExpired: "Your access to this course has expired.",
    yourPasswordMustContain: "Your password must contain:",
  },
  placeholders: {
    empty: "—",
  },
  help: {
    aboutUs: `AIDIFY is a subscription-based learning experience platform, supported by machine learning, 
      designed to personalize, automate and standardize the process of employee development in the pharma industry.`,
    ourMission: `Our mission is to provide high-quality training materials prepared by subject-matter experts 
      to ensure company’s compliance with the ever-changing legal environment in the area of 
      Pharmacovigilance, Clinical Trials and Regulatory Affairs. <br/><br/>
      We will help you to stay ahead of the competition and drive business growth! 
      Explore our personalized and competence-based training programs and let AIDIFY help you achieve your business goals!`,
    registrationStep2: `During this step, you can define your current skillset as well as the skills 
      you would like to learn in the near future. This will serve as a good starting point for our AI 
      to assist you in reaching your goals more efficiently.<br/><br/>
      In the upper section, you can find a list of competences that have been proposed by AIDIFY 
      based on your current job position. For each competence, you can specify:
      <ul>
        <li>Your goal level of knowledge</li>
        <li>Your current level of knowledge</li>
        <li>The priority of this particular competence in your learning process</li>
      </ul>
      Please note that setting the current level of competence requires verification 
      to be fully integrated into the AIDIFY learning process. This verification will be done in the next step.<br/><br/>
      If you would like to add more competences, please use the "Add more" section below and select from the AIDIFY competence database.`,
    studentCompetences: `In this view you can define your current skillset aswell as the skills you'd like to learn in the nearest 
      future - this will be a good start point for our AI to help you reach your goals faster.<br/><br/>
      In the upper section you can see all of the competences that are already assinged to you. For every 
      competence you can specify
      <ul>
        <li>your goal level of knowledge,</li>
        <li>your current level of knowledge,</li>
        <li>the priority of this particular competence in your learning process.</li>
      </ul>
      Please notice, that setting the current level of competence still requires verification in order to be fully 
      included in AIDIFY learning process. The best way to do it, is completing AIDIFY courses that 
      additionally gives you certificate of course completion. You can also use "Manual verification" option in 
      the top upper corner of every competence.<br/><br/>
      If you'd like to add more competences, use the "Add more" section below and pick something from AIDIFY competence database.`,
  },
  navBar: {
    menuLinks: [
      [
        // {
        //   label: "Wizard",
        //   icon: "creator",
        //   route: () => {},
        // },
        {
          label: "Career Paths",
          icon: "careerPaths",
          route: Routes.careerPaths,
        },
        {
          label: "Job Positions",
          icon: "jobPosition",
          route: Routes.jobPositions,
        },
        {
          label: "Competences",
          icon: "competences",
          route: Routes.competences,
        },
        {
          label: "Courses",
          icon: "courses",
          route: Routes.courses,
        },
        {
          label: "Reference Control",
          icon: "reference_control",
          route: Routes.referenceControl,
        },
        {
          label: "Learning Events",
          icon: "learningEvents",
          route: Routes.learningEvents,
        },
        {
          label: "Regulatory Intelligence",
          icon: "regulatory",
          route: Routes.regulatoryIntelligence,
        },
      ],
      [
        {
          label: "Marketplace",
          icon: "marketplace",
          route: Routes.marketplace,
        },
        // {
        //   label: "Forum",
        //   icon: "forum",
        //   route: () => {},
        // },
        {
          label: "Chat",
          icon: "chat",
          route: Routes.chat,
        },
      ],
    ],
    userLinks: (contextType) => {
      switch (contextType) {
        case 0:
          return [
            [
              {
                label: "My Profile",
                icon: "profile",
                route: Routes.profile.statistics,
                params: (userData) => ({ username: userData.username }),
              },
              {
                label: "My IDPs",
                icon: "IDP",
                route: Routes.idps,
                params: (userData) => ({ username: userData.username }),
              },
              {
                label: "My Competences",
                icon: "competences",
                route: Routes.studentCompetences,
              },
              {
                label: "My Profession",
                icon: "profession",
                route: Routes.profession,
                params: (userData) => ({ username: userData.username }),
              },
              {
                label: "My Learning Report",
                icon: "learningReport",
                route: Routes.studentLearningReport,
                params: (userData) => ({ username: userData.username }),
              },
            ],
            [
              {
                label: "My Team",
                icon: "team",
                route: Routes.team,
                params: (userData) => ({ id: userData.team_id }),
                visible: (userData) => userData.team_id,
              },
              {
                label: "My Workgroups",
                icon: "workgroups",
                route: Routes.workgroups,
              },
              {
                label: "My Files",
                icon: "files",
                route: Routes.files,
              },
            ],
          ];
        case 1:
          return [
            [
              {
                label: "My Profile",
                icon: "profile",
                route: Routes.profile.statistics,
                params: (userData) => ({ username: userData.username }),
              },
              {
                label: "My Team",
                icon: "team",
                route: Routes.team,
                params: (userData) => ({ id: userData.team_id }),
              },
              {
                label: "Team Learning Report",
                icon: "learning_report",
                route: Routes.learningReport.competences,
                params: (userData) => ({ type: userData.current.context_type, id: userData.current.context_id }),
              },
            ],
            [
              {
                label: "My Workgroups",
                icon: "workgroups",
                route: Routes.workgroups,
              },
              {
                label: "My Files",
                icon: "files",
                route: Routes.files,
              },
            ],
          ];
        case 2:
          return [
            [
              {
                label: "My Profile",
                icon: "profile",
                route: Routes.profile.statistics,
                params: (userData) => ({ username: userData.username }),
              },
              {
                label: "My Organization",
                icon: "organization_icon",
                route: Routes.organization.statistics,
                params: (userData) => ({ id: userData.org_id }),
              },
              {
                label: "Organization Licenses",
                icon: "license",
                route: Routes.organizationLicenses,
              },
              {
                label: "Org. Learning Report",
                icon: "learning_report",
                route: Routes.learningReport.competences,
                params: (userData) => ({ type: userData.current.context_type, id: userData.current.context_id }),
              },
            ],
            [
              {
                label: "My Workgroups",
                icon: "workgroups",
                route: Routes.workgroups,
              },
              {
                label: "My Files",
                icon: "files",
                route: Routes.files,
              },
            ],
          ];
        case 3:
          return [
            [
              {
                label: "My Profile",
                icon: "profile",
                route: Routes.profile.statistics,
                params: (userData) => ({ username: userData.username }),
              },
              {
                label: "License Control",
                icon: "license_control",
                route: Routes.licenseControl,
              },
              {
                label: "Learning Report",
                icon: "learningReport",
                route: Routes.learningReport.competences,
                params: (userData) => ({ type: userData.current.context_type, id: userData.current.context_id }),
              },
            ],
            [],
          ];
        default:
          return [
            [
              {
                label: "My Page",
                icon: "profile",
                route: Routes.instructorDetails,
                params: (userData) => ({ id: userData.current.context_id }),
              },
            ],
            []
          ];
      }
    },
  },
  footer: {
    navLists: [
      {
        header: "Learn More",
        items: [
          {
            label: "About AIDIFY",
            action: () => window.open("https://join.aidify.eu/", '_blank'),
          },
          // {
          //   label: "Product features",
          //   action: () => window.open("https://join.aidify.eu/", '_blank'),
          // },
          // {
          //   label: "User manual",
          //   action: () => window.open("https://join.aidify.eu/", '_blank'),
          // },
          // {
          //   label: "FAQ",
          //   action: () => window.open("https://join.aidify.eu/", '_blank'),
          // },
          // {
          //   label: "Subscriptions plans",
          //   action: () => window.open("https://join.aidify.eu/", '_blank'),
          // },
          {
            label: "Contact",
            action: () => window.open("https://join.aidify.eu/", '_blank'),
          },
        ],
      },
      // {
      //   header: "Become Instructor",
      //   items: [
      //     {
      //       label: "Values for SME",
      //       action: () => window.open("https://join.aidify.eu/", '_blank'),
      //     },
      //     {
      //       label: "Apply Now",
      //       action: () => window.open("https://join.aidify.eu/", '_blank'),
      //     },
      //   ],
      // },
      {
        header: "Privacy & Terms",
        items: [
          {
            label: "Terms of use",
            popupPayload: (language, userData) => ({
              header: language.labels.termsAndConditions,
              content: language.registerPage.termsAndConditions[userData.is_b2c ? 'b2c' : 'b2b'],
            }),
          },
          {
            label: "Privacy Policy",
            popupPayload: (language) => ({
              header: language.labels.privacyPolicy,
              content: language.registerPage.privacyPolicy,
            }),
          },
          {
            label: "Cookie Policy",
            popupPayload: (language) => ({
              header: language.labels.cookiesPolicy,
              content: language.registerPage.cookiesPolicy,
            }),
          },
        ],
      },
      {
        header: "Your account",
        items: (contextType) => [
          ...languages.EN.navBar.userLinks(contextType)[0],
          // {
          //   label: "Logout",
          //   action: () => {
          //   },
          // }
        ],
      },
    ],
    socialMedias: [
      // {
      //   icon: Icons.vimeo,
      //   link: "https://www.youtube.com/",
      // },
      {
        icon: Icons.youtube,
        link: "https://www.youtube.com/@aidify8520",
      },
      // {
      //   icon: Icons.twitter,
      //   link: "https://www.youtube.com/",
      // },
      {
        icon: Icons.linkedin,
        link: "https://www.linkedin.com/company/aidify",
      },
      // {
      //   icon: Icons.instagram,
      //   link: "https://www.youtube.com/",
      // },
      {
        icon: Icons.facebook,
        link: "https://www.facebook.com/Aidify-102687041284297you",
      },
    ],
  },
  registerPage: {
    privacyPolicy: `
<p><i>Last updated on 01.08.2023</i></p> 

<p><b>1. Who are we?</b></p> 
<p>A controller of your personal data processed in our services AIDIFY sp. z o.o. with its registered office in Lublin (ZIP code: 20-418), ul. Nowy Świat 11A, entered into the Register of the National Court Register kept by the District Court Lublin-Wschód in Świdnik, 6th Commercial Division under NCR number: 0000791766 (“we”, “us”). That means we decide how your data is processed on it by determining the purposes and means of the processing of personal data.</p> 
<p>We prepared this Privacy Policy because your right to privacy is important to us and we want you to understand how we collect, use, and share data about you</p>
<p>If you have any questions to us, please contact us by writing an e-mail to contact@aidfy.eu and adding the “Data protection” in the subject line.</p>

<p><b>2. What personal data we collect?</b></p>
<p>We collect only personal data necessary for exercising our purposes as set out below. Providing us with your personal data is not mandatory but it may be necessary to access the platform’s content and to be able to use our services</p> 
<p>We collect certain data from you directly (e.g. Account and Profile Data as defined below) data from third-party platforms you connect with AIDIFY. We also collect some data automatically (e.g. System Data), through the use of server log files and tracking technologies. For details please consult our Cookie Policy.</p>

<p><b>2.1 Categories of data</b></p>
<table>
  <tr>
    <th>Category of personal data</th>
    <th>Description</th>
    <th>Legal basis and purpose of processing</th>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Account Data</b></td>
    <td>In order to access the platform’s content, you need to create a user account, which requires us to collect and store your email address, password, and account settings. As you use certain features on the site, you may be prompted to submit additional information including (but not limited to) ling, your career path. Upon account creation, we assign you a unique identifying number.</td>
    <td>
      <ul>
        <li>Performance of contract</li>
        <li>Legitimate interests (service provisioning, identity verification, fraud prevention and security, communication)</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Profile Data</b></td>
    <td>Profile information include a photo, headline, biography, language, website link, social media handles, country, or other data. Providing us with this information is optional. The availability of profile data depends on user settings.</td>
    <td>
      <ul>
        <li>Performance of contract</li>
        <li>Legitimate interests (enhanced platform functionality, convey content source information</li>
      <ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Shared Content</b></td>
    <td>Since some services we provide in the platform let you interact with other users or share content publicly, we process data related to those activities including data in uploaded courses and other educational content, reviews about content, your questions and answers, messages sent to other course attendees or SMEs, or posting photos or other work you upload. Such shared content may be publicly viewable by others depending on where it is posted.</td>
    <td>
      <ul>
        <li>Performance of contract</li>
        <li>Legitimate interests (service provisioning, enhanced platform functionality) </li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Learning Data</b>/td>
    <td>We also collect certain data related to the platform’s content you access including courses, assignments, labs, workspaces, and quizzes you’ve started and completed; content and subscription purchases and credits; subscriptions; completion certificates; your exchanges with SMEs, and other course attendees, answers to questions, and other items submitted to satisfy course and related content requirements.</td>
    <td>
      <ul>
        <li>Performance of contract </li>
        <li>Legitimate interests (service provisioning, enhanced platform functionality) </li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Organization Payment Data</b></td>
    <td>We collect certain data about your purchase (such as your name, billing address, and ZIP code and other data from invoices) as necessary to process your order and which may optionally be saved to process future orders.</td>
    <td>
      <ul>
        <li>Performance of contract</li>
        <li>Legal obligation</li>
        <li>Legitimate interests (payment facilitation, fraud prevention and security, compliance)</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>SME Payment Data</b></td>
    <td>If you are a subject matter expert (“SME”) we collect and process certain information, including the email address associated with your account, your account ID, physical address or other data necessary to send payments assigned to your account. In certain situations we may collect data from an automated payment systems or wire transfer information to send payments to your account. To ensure compliance with applicable laws, we work with trusted third-party companies that collect information for tax purposes required by law. This information may include location data, tax identification numbers, biographical information and other personal information necessary for tax purposes.</td>
    <td>
      <ul>
        <li>Performance of contract</li>
        <li>Legal obligation</li>
        <li>Legitimate interests (service provisioning, payment facilitation, fraud prevention and security, compliance)</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Data About Your Accounts on Other Services</b></td>
    <td>We may obtain certain information through your social media or other online accounts if they are connected to your AIDIFY account. If you log in to AIDIFY via third-party platform or service (such as SSO), we ask for your consent to access certain information about that other account. The information we receive depends on what information are available on that platform/service (including via your privacy settings there) as those platforms and services make information available to us through their APIs. If you access or use our services through a third-party platform or service, or click on any third-party links, the collection, use, and sharing of your data will also be subject to the privacy policies and other agreements of that third party.</td>
    <td>
      <ul>
        <li>Legitimate interests (identity verification, user experience improvement)</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Sweepstakes, Promotions, and Surveys</b></td>
    <td>We may invite you to complete a survey or participate in a promotion (like a contest, challenge or sweepstakes), either through our services or a third-party platform. If you participate, we will collect and store the data you provide as part of participating, such as your name, email address, postal address, or phone number, for administer the promotion or survey, including for notifying winners and distributing rewards. To receive a reward, you may be required to allow us to post some of your information publicly (like on a winner’s page). Where we use a third-party platform to administer a survey or promotion, the third party’s privacy policy will apply.</td>
    <td>
      <ul>
        <li>Performance of contract</li>
        <li>Legitimate interests (promotions administration, prize delivery, compliance)</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Contact and Support</b></td>
    <td>If you contact us for support or to report a problem or concern (regardless of whether you have created an account), we collect and store your contact information, messages, and other data about you like your name, email address, messages, location, user ID, refund transaction IDs, and any other data you provide or that we collect through automated means (which we cover below). We use this data to respond to you and research your question or concern.</td>
    <td>
      <ul>
        <li>Legitimate interests (customer and technical support)</li>
      </ul>
    </td>
  </tr>
  
  <tr><th colspan="3">Data collected through use of automated means</th></tr>
  <tr>
    <td style="background:#E7E6E6;"><b>System Data</b></td>
    <td>
      <span>Technical data about your computer or device, like your IP address, device type, operating system type and version, unique device identifiers, browser, browser language, domain and other systems data, and platform types.</span>
    </td>
    <td>
      <ul>
        <li>Performance of contract</li>
        <li>Legitimate interests (service provisioning, customer and technical support, fraud prevention and security, communication, product improvement)</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Usage Data</b></td>
    <td>Usage statistics about your interactions with the services, including content accessed, time spent on pages or the Service, pages visited, features used, your search queries, click data, date and time, referrer, and other data regarding your use of the Services.</td>
    <td>
      <ul>
        <li>Legitimate interests (service provisioning, user experience improvement, product improvement)</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Approximate Geographic Data</b></td>
    <td>An approximate geographic location, including information like country, city, and geographic coordinates, calculated based on your IP address.</td>
    <td>
      <ul>
        <li>Legitimate interests (user experience improvement, fraud prevention and security, compliance)</li>
      </ul>
    </td>
  </tr>
</table>
    
<p><b>2.2 Categories of users</b></p>
<p>Data we collect and process about you depends on capacity in which you use our services.</p>
<table>
  <tr>
    <th>Categories of users</th>
    <th>Description</th>
    <th>Categories of personal data we process</th>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Administrator</b></td>
    <td>A user managing the profile of the organization in the AIDIFY platform on its behalf. Administrators do not have access to the platform’s content.</td>
    <td>Organization Payment Data, Contact and Support, System Data, Approximate Geographic Data</td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Manager</b></td>
    <td>A user with access to the educational content contained in the AIDIFY platform) and is assigned in the role of manager to at least one team within their organization. The Manager is responsible for managing the team within the organization.</td>
    <td rowspan="2">Account Data, Profile Data, Shared Content, Learning Data, Data About Your Accounts on Other Services, Sweepstakes, Promotions, and Surveys, Contact and Support, System Data, Usage Data, Approximate Geographic Data</td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Course Attendant</b></td>
    <td>A user with access to the educational content contained in the AIDIFY platform whose main objective is to learn using the resources of the platform.</td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Subject Matter Expert (“SME”)</b></td>
    <td>A user who is the author of at least one course in the AIDIFY platform, and whose main objective is to analyse and conduct interactions assigned to their course with other users as well as to review the statistics of the courses they teach.</td>
    <td>Account Data, Profile Data, Shared Content, Learning Data, Data About Your Accounts on Other Services, Sweepstakes, Promotions, and Surveys, Contact and Support, System Data, Usage Data, Approximate Geographic Data, SME Payment Data</td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>User</b></td>
    <td>Each user that created an account in the AIDIFY platform.</td>
    <td>Account Data, Profile Data, Shared Content, Data About Your Accounts on Other Services, Sweepstakes, Promotions, and Surveys, Contact and Support, System Data, Usage Data, Approximate Geographic Data</td>
  </tr>
  <tr>
    <td style="background:#E7E6E6;"><b>Guest</b></td>
    <td>Each person browsing AIDIFY services without logging in the platform.</td>
    <td>Contact and Support, System Data, Usage Data, Approximate Geographic Data</td>
  </tr>
</table>

<p><b>3. Who do we share your data with?</b></p>
<p>We share certain data about you with SMEs, other course attendees and managers of your teams, our business partners and third-party providers (of such services as accounting and tax addvisory services, legal advisors, professional training services, IT systems, auditors, course and teaser recording and editing services).</p> 
<p>Those recipients of your personal data may be located in countries outside the European Union (“EU”) or European Economic Area (“EEA”). Personal data will only be transferred outside the EU or EEA either to countries that the European Commission has confirmed an adequate level of data protection or with implementation of appropriate safeguards.</p>

<p><b>4. For how long we will process your personal data?</b></p> 
<p>We will only process your personal data for the period necessary to fulfil the purposes of the processing as determined in 2.1.</p> 
<p>For the marketing purposes, we will process your personal data until you object to the processing.</p> 
<p>If our processing of your data is based on your consent (e.g. when we process personal data in cookies other than necessary cookies), you may also withdraw your consent at any time.</p> 

<p><b>5. What rights do you have with regard to data we process?</b></p> 
<p>In accordance with the provisions of GDPR (Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC), you may exercise your following rights:</p> 
<ul>
  <li>Right to information on personal data processed by us and access to this data;</li> 
  <li>Right to rectification of inaccurate or incomplete personal data that concerns you;</li> 
  <li>Right to erasure of your personal data;</li> 
  <li>Right to restriction of processing;</li> 
  <li>Right to data portability;</li> 
  <li>Right to object to processing of your personal data.</li> 
</ul>
<p>You also have the right to lodge a complaint with a President of the Personal Data Protection Office (https://www.uodo.gov.pl/, Prezes Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa, Poland). However, before you do, we encourage you to contact us directly with any concern you may have.</p>  
      `,
    termsAndConditions: {
      b2b: `
<p>These Terms of Use (“Terms”), as amended and restated on 12 Dec. 23, set out the terms, scope and conditions of use of the services ("Services") provided via a Learning Experience Platform available at aidify.eu (“Platform”) owned by Aidify Sp. z o.o. with its registered office in Lublin (ZIP code: 20-418), ul. Nowy Świat 11A, entered into the Register of the National Court Register kept by the District Court Lublin-Wschód in Świdnik, 6th Commercial Division under NCR number: 0000791766 (“AIDIFY”).</p>
<p>The Platform contains e-learning courses and additional content and materials available in electronic form, in accordance with the choice of service designated for you as a user of the Platform by a company you work for (“Company”).</p>
<p>These Terms apply to any user of the Platform (“User”), except for a consumer and an entrepreneur having the rights of a consumer, i.e. a User being an individual and concluding an agreement for Services between them as a User and AIDIFY, the subject matter of which is directly related to the business or professional activity a User carries out, or the subject matter of which is directly related to a User’s business activity, but from the content of that agreement it follows that it does not have a professional character for a User, arising in particular from the subject matter of the business activity that User carries out, made available on the basis of the provisions on the Central Register and Information on Business Activity, as well as all their activities on the Platform.</p>
<p>By using our Services available through the Platform you accept these Terms and you are obliged to comply with the rules contained herein. By using the Platform, you accept the provisions of these Terms.</p>
<p>We also provide details regarding our processing of personal data of our Users in our Privacy Policy.</p>
<p><b>1. Accounts</b></p>
<p>You need an account for most activities on our Platform, including to access the content.</p>
<p>When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address and you must accept these Terms. An account is linked to an e-mail address provided during the User’s registration (account creation).</p>
<p>You gain access to the full functionality of the Platform according to the selected Services after activation of your account by AIDIFY, of which you are informed by e-mail.</p>
<p>As a registered User you may modify/delete your data contained in the “Profile” tab at any time in accordance with our Privacy Policy. AIDIFY is not responsible for the accuracy of a User's data provided during registration.</p>
<p>AIDIFY has the right to refuse to register any User and/or to block the account of a User who violates the provisions of these Terms. In such a case, the User will not be entitled to compensation for lack of access to the selected Services.</p>
<p>You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. You must protect the password and login in such a way that it cannot be made known to third parties. You may not transfer your account to someone else or use someone else’s account. You may not share your account login credentials as well as any content available through the Platform with anyone else (in particular, but not only, it is prohibited to display it by any means, e.g. an overhead projector). You are responsible for what happens with your account.</p>
<p>In the event of a breach of the above provisions, you are entirely responsible for the actions of such third parties, and AIDIFY as the Platform’s administrator shall be entitled to block your access to the Platform. In the event that access to the Platform is blocked for the reasons set out in this paragraph, you are not entitled to claim a refund of the fee paid.</p>
<p>If you contact us to request access to an account, we will not grant you such access unless you can provide us with the information that we need to prove you are the owner of that account. In the event of the death of a User, the account of that user will be closed.</p>
<p>You must notify us immediately upon learning that someone else may be using your account without your permission (or if you suspect any other breach of security) by contacting our Support Team (contact@aidify.eu). We may request some additional verification from you to confirm that you are indeed the owner of your account.</p>
<p>You can terminate your account at any time by contacting our Support Team (contact@aidify.eu).</p>
<p><b>2. Conclusion of an agreement for Services</b></p>
<p>An agreement for Services for a User (“Agreement”) is concluded by the User’s Company placing an order for granting an access to the selected Services through the Platform, i.e. making a declaration of intent to use the selected Services through the Platform.</p>
<p>You will be granted access to the ordered Services as set out during correspondence with AIDIFY. An email will be sent to the email address indicated by your Company informing you that the Services have been made available on the Platform.</p>
<p><b>3. Content Enrollment</b></p>
<p>Access to the Platform is time-limited. The materials placed on the Platform are subject to copyright in accordance with the Act of 4 February 1994 on Copyright and Related Rights (Journal of Laws 1994 no. 24 item 83).</p>
<p>AIDIFY grants a Company you work for (as a User) a limited, non-exclusive, non-transferable license to access and view the content for which the Company has paid all required fees, solely for your personal, non-commercial, educational purposes through the Services, in accordance with these Terms and any conditions or restrictions associated with the particular content or feature of our Services. You as a User agree that all other uses are expressly prohibited. You may not copy, reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, sublicense, or otherwise transfer or use any content unless we give the Company explicit permission to do so in a written agreement signed by an AIDIFY authorized representative.</p>
<p>We reserve the right to revoke any license to access and use any content at any point in time in the event that we decide or are obligated to disable access to the content due to legal or policy reasons, for example, if the course or other content you enrolled in is the object of a copyright complaint.</p>
<p><b>4. Technical requirements</b></p>
<p>The Platform is accessible exclusively via the Internet and operates 7 days a week, 24 hours a day, excluding periods necessary for modernisation work or excluding periods of failure. The technical computer requirements for the correct use of the resources of the Platform are:</p>
<ol type="a">
  <li>access to the Internet;</li>
  <li>current browser: Mozilla Firefox, Safari, Microsoft Edge, Opera, Chrome;</li>
  <li>headphone jack or speakers;</li>
  <li>an active e-mail account.</li>
</ol>
<p>The use of all the possibilities of the Platform requires the activation of cookies.</p>
<p>In the event of any problems related to the functioning of the Platform, you should contact our Support Team (contact@aidify.eu), who will take the appropriate steps to enable you to use the Platform correctly and participate in the training courses.</p>
<p>AIDIFY is not responsible for inadequate configuration of a User's computer. AIDIFY is not responsible for cases where a User fails to participate in the training due to hardware problems with the User’s computer.</p>
<p><b>5. Content and Behavior Rules</b></p>
<p>You can only use AIDIFY for lawful purposes. You’re responsible for all the content that you post on our Platform. You should keep the reviews, questions, posts, courses and other content you upload in line with the law, and respect the intellectual property rights of others. We can ban your account for repeated or major offenses. If you think someone is infringing your copyright on our Platform, let us know.</p>
<p>You may not access or use the Services or create an account for unlawful purposes. Your use of the Services and behavior on our Platform must comply with applicable laws or regulations.</p>
<p>If you are a User, the Services enable you to ask questions to the subject matter experts (“SME”) of courses or other content you are enrolled in, and to post reviews of content. Don’t post or submit anything that is not yours.</p>
<p>If we are put on notice that your course or content violates the law or the rights of others (for example, if it is established that it violates intellectual property or image rights of others, or is about an illegal activity), if we believe your content or behavior is unlawful, inappropriate, or objectionable (for example if you impersonate someone else), we may remove your content from our Platform. AIDIFY complies with copyright laws.</p>
<p>AIDIFY has discretion in enforcing these Terms. We may restrict or terminate your permission to use our Platform and Services or ban your account at any time, with or without notice, for any violation of these Terms, for fraudulent chargeback requests, upon the request of law enforcement or government agencies, or if we suspect that you engage in fraudulent or illegal activities. Upon any such termination we may delete your account and content, and we may prevent you from further access to the Platform and use of our Services. Your content may still be available on the Platform even if your account is terminated or suspended. You agree that in case of violation of these Terms we will have no liability to you or any third party for termination of your account, removal of your content, or blocking of your access to our Platform and Services.</p>
<p>If a User has published content that infringes your copyright or trademark rights, please let us know.</p>
<p><b>6. Using AIDIFY at Your Own Risk</b></p>
<p>Our Platform model means that except for the courses and supplementary materials created by our SMEs and available on the Platform, we do not review or edit any other content for legal issues, and we are not in a position to determine the legality of the users’ submitted content. We do not exercise any editorial control over the users’ submitted content that is available on the Platform (except for the above mentioned courses and supplementary materials created by our SMEs) and, as such, do not guarantee in any manner the reliability, validity, accuracy, or truthfulness of that content. If you access such content, you rely on any information provided by any user at your own risk.</p>
<p>By using the Services, you may be exposed to content that you consider offensive, indecent, or objectionable. AIDIFY has no responsibility to keep such content from you and no liability for your access or enrollment in any course or other content, to the extent permissible under applicable law. This also applies to any content relating to health, wellness, and physical exercise. You acknowledge the inherent risks and dangers in the strenuous nature of these types of content, and by accessing such content you choose to assume those risks voluntarily, including risk of illness, bodily injury, disability, or death. You assume full responsibility for the choices you make before, during, and after your access to the content.</p>
<p>We are not responsible or liable for any interactions involved between SMEs (subject matter experts) and Users. We are not liable for disputes, claims, losses, injuries, or damage of any kind that might arise out of or relate to the conduct of SMEs or Users.</p>
<p>When you use our Services, you will find links to other websites that we don’t own or control. We are not responsible for the content or any other aspect of these third-party sites, including their collection of information about you. You should also read their terms and conditions and privacy policies.</p>
<p><b>7. AIDIFY’s Rights</b></p>
<p>We own the Platform and Services, including the website, present or future apps and services, and things like our logos, API (Application Programming Interface), code, and content created by our employees. You can’t tamper with those or use them without authorization.</p>
<p>All rights, title, and interest in and to the Platform and Services, including our website, our existing or future applications, our APIs, databases, and the content our employees or partners submit or provide through our Services (but excluding content provided by Users) are and will remain the exclusive property of AIDIFY  and its licensors. Our Platform and Services are protected by copyright, trademark, and other laws. Nothing gives you a right to use the AIDIFY name or any of the AIDIFY trademarks, logos, domain names, and other distinctive brand features without our prior explicit consent to do so. Any feedback, comments, or suggestions you may provide regarding AIDIFY or the Services is entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you.</p>
<p>You may not do any of the following while accessing or using the Platform and Services:</p>
<ol type="a">
  <li>access, tamper with, or use non-public areas of the Platform (including content storage), AIDIFY’s computer systems, or the technical delivery systems of AIDIFY’s service providers;</li>
  <li>browsing and analysing the Platform, its features, and available content in order to create or develop a competitive solution to the Platform or activity in the same business area;</li>
  <li>disable, interfere with, or try to circumvent any of the features of the Platform related to security or probe, scan, or test the vulnerability of any of our systems;</li>
  <li>copy, modify, create a derivative work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of or content on the Platform or Services;</li>
  <li>access or search or attempt to access or search our Platform by any means (automated or otherwise) other than through our currently available search functionalities that are provided via our website, mobile apps, or API (and only pursuant to those API terms and conditions). You may not scrape, spider, use a robot, or use other automated means of any kind to access the Services;</li>
  <li>in any way use the Services to send altered, deceptive, or false source-identifying information (such as sending email communications falsely appearing as AIDIFY); or</li>
  <li>interfere with, or disrupt, (or attempt to do so), the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Platform or Services, or in any other manner interfering with or creating an undue burden on the Services.</li>
</ol>
<p><b>8. Subscription Terms</b></p>
<p>There may be an Agreement between your Company and AIDIFY that covers additional terms that apply to your use of our subscription-based collections as a User, in particular, account management, free trials and renewals, and billing and payment provisions. In the event that no such agreement exists, all arrangements are based on these Terms and previous arrangements between You and the AIDIFY team.</p>
<p><b>8.1. Subscription Plans</b></p>
<p>During your subscription to the chosen Service, you get a limited, non-exclusive, non-transferable license from us to access and view the content included in the chosen Service through the Platform.</p>
<p>The subscription that you purchase or renew determines the scope, features, and price of your access to a chosen Service. You may not transfer, assign, or share your subscription with anyone else.</p>
<p><b>8.2. Account Management</b></p>
<p>You may cancel your subscription by following the steps outlined by AIDIFY. If you cancel your subscription to a chosen Service, your access to that chosen Service will automatically end on the last day of your billing period. On cancellation, you will not be entitled to receive a refund or credit of any fees already paid for your subscription, unless otherwise required by applicable law. For clarity, cancellation of a subscription does not terminate your AIDIFY account.</p>
<p><b>8.3. Free Trials & Renewals</b></p>
<p>Your subscription may start with a free trial. The duration of the free trial period of your subscription is 14 days during which you will have access to the Platform and Users are entitled to a total of 2 hours to watch video courses and engage in practice activities. You may only use a free trial once. When you attempt to use a free trial, you will be notified whether you are eligible to use a free trial.</p>
<p>In order to use a free trial, the User should tick the option when completing the order form: "Activate Freemium", then complete the subsequent steps of the interactive form or contact AIDIFY in any other way. The Agreement (with respect to a free trial) is concluded at the moment of accepting the Terms during the registration to the AIDIFY Platform.</p>
<p>AIDIFY determines free trial eligibility at our sole discretion and may limit access, eligibility, or duration. We reserve the right to terminate the free trial and suspend your subscription if we determine that you aren’t eligible.</p>
<p>If the Company has not paid the subscription price by the end of a free trial period, at the end of the free trial period the Agreement is terminated and AIDIFY is not obliged to continue to provide access services to the Platform.</p>
<p><b>8.4. Subscription Disclaimers</b></p>
<p>At any point in the future, we reserve the right to offer or cease to offer additional features to any Service or to otherwise modify or terminate a Service at our sole discretion (none of these shall in any way affect the rights acquired prior to the effective date of such modification or termination). We have no responsibility to preserve or store the content inputted by you in connection with your use of any Service. These disclaimers are in addition to those listed in the “Disclaimers” section below.</p>
<p><b>9. Claims</b></p>
<p>AIDIFY shall undertake actions to ensure the fully correct functioning of the Platform to the extent resulting from the current technical knowledge and undertakes to remove all irregularities reported by the Users within a reasonable period of time. A User is obliged to immediately notify AIDIFY of any irregularities or interruptions in the functioning of the Services.</p>
<p>As a User you have the right to lodge a complaint regarding the provision of the Services. If the data or information provided in the complaint needs to be supplemented, before the complaint is considered, AIDIFY shall request a User submitting the complaint to supplement them in the indicated scope.</p>
<p>Complaints should be submitted electronically via our Support Team (contact@aidify.eu).</p>
<p>A User shall be informed about the manner of resolving the complaint by e-mail or telephone, unless another method of providing such information has been agreed with a User.</p>
<p>AIDIFY reserves the right to intervene in the technical structure of a User's account in order to diagnose irregularities in the functioning of the Services, as well as to make changes and influence in any other way the technical side of the account in order to modify it or to restore the proper functioning of the account itself or the Services.</p>
<p><b>10. Miscellaneous Legal Terms</b></p>
<p><b>10.1. Binding Agreement</b></p>
<p>You agree that by registering, accessing, or using our Services, you are agreeing to enter into a legally binding contract with AIDIFY. If you do not agree to these Terms, do not register, access, or otherwise use any of our Services.</p>
<p>Any version of these Terms in a language other than English is provided for convenience and you understand and agree that the English language will control if there is any conflict.</p>
<p>These Terms (including any agreements and policies linked to these Terms) constitute the entire agreement between you and us.</p>
<p>If any part of these Terms is found to be invalid or unenforceable by applicable law, then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms will continue in effect.</p>
<p><b>10.2. Disclaimers</b></p>
<p>The Services and their content are provided on an “as is” and “as available” basis. We (and our affiliates, suppliers, partners, and agents) make no representations or warranties about the suitability, reliability, availability, timeliness, security, lack of errors, or accuracy of the Services or their content, and expressly disclaim any warranties or conditions (express or implied), including implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, unless such representations or warranties are explicitly given under an Agreement. We (and our affiliates, suppliers, partners, and agents) make no warranty that you will obtain specific results from use of the Services. Your use of the Services (including any content) is entirely at your own risk.</p>
<p>We may decide to cease making available certain features of the Services at any time and for any reason. Under no circumstances will AIDIFY or its affiliates, suppliers, partners or agents be held liable for any damages due to such interruptions or lack of availability of such features.</p>
<p>We are not responsible for delay or failure of our performance of any of the Services caused by events beyond our reasonable control, like an act of war, hostility, or sabotage; natural disaster; electrical, internet, or telecommunication outage; or government restrictions.</p>
<p><b>10.3. Limitation of Liability</b></p>
<p>AIDIFY shall not be liable for the occurrence on the part of a User of any damage caused by the User's use of, or inability to use, the Platform, including loss of data, delays in receiving data or delays in the transmission of data due to lack of transmission, incorrect transmission, delays or other interruptions in the provision of Services caused by factors beyond AIDIFY’s control.</p>
<p>Factors beyond AIDIFY’s control are considered to be, in particular:</p>
<ol type="a">
  <li>failures of transmission or telephone lines (including international ones), load on the lines, lack of access to the networks of national and foreign operators or irregularities in the operation of such telecommunications operators,</li>
  <li>interruptions in the supply of electricity and failures of technical equipment and other such circumstances for which service providers and telecommunications operators are responsible or force majeure, understood as a sudden, unexpected external cause for which AIDIFY is not responsible,</li>
  <li>necessary maintenance, repair and other interruptions of a technical nature.</li>
</ol>
<p>AIDIFY reserves the right to shut down the Platform in whole or in part for an indefinite period of time in order to modernise or repair it.</p>
<p><b>10.4. Indemnification</b></p>
<p>Unless it has not been agreed otherwise under the Agreement, to the extent permitted by law, we (and our suppliers, partners, and agents) will not be liable for any indirect, incidental, punitive, or consequential damages (including loss of data, revenue, profits, or business opportunities, or personal injury or death), whether arising in contract, warranty, tort, product liability, or otherwise, and even if we’ve been advised of the possibility of damages in advance. Our liability (and the liability of each of our suppliers, partners, and agents) to you or any third parties under any circumstance is limited to the amount you have paid us in the twelve (12) months before the event giving rise to your claims.</p>
<p>If you behave in a way that gets us in legal trouble, we may exercise legal recourse against you. You agree to indemnify, defend (if we so request), and hold harmless AIDIFY and their officers, directors, suppliers, partners, and agents from and against any third-party claims, demands, losses, damages, or expenses (including reasonable attorney fees) arising from (a) the content you post or submit, (b) your use of the Services (c) your violation of these Terms, or (d) your violation of any rights of a third party. Your indemnification obligation will survive the termination of these Terms and your use of the Services.</p>
<p><b>10.5. Governing Law and Jurisdiction</b></p>
<p>These Terms are governed by the laws of Poland, and you consent to the exclusive jurisdiction and venue of the common courts competent for the capital city Warsaw in Warsaw, Poland.</p>
<p><b>10.6. Legal Actions and Notices</b></p>
<p>No action, regardless of form, arising out of or relating to an Agreement (including these Terms) may be brought by either party more than one (1) year after the cause of action has accrued, except where this limitation cannot be imposed by law.</p>
<p>Any notice or other communication to be given hereunder will be in writing and given by registered or certified mail return receipt requested, or email (by us to the email associated with your account or by you to contact@aidify.eu).</p>
<p><b>10.7. No Assignment</b></p>
<p>You may not assign or transfer these Terms (or the rights and licenses granted under them). For example, if you registered an account as an employee of a Company, your account cannot be transferred to another employee. We may assign these Terms (or the rights and licenses granted under them) to another company or person without restriction once during the duration of the given access period. Nothing in these Terms confers any right, benefit, or remedy on any third-party person or entity. You agree that your account is non-transferable and that all rights to your account and other rights under these Terms terminate upon your death.</p>
<p><b>11. Updating These Terms</b></p>
<p>From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and AIDIFY reserves the right in its sole discretion to modify and/or make changes to these Terms at any time.</p>
<p>If we make any change, we will notify you using prominent means, such as by email notice sent to the email address specified in your account, at least 14 days before the updated version comes into force. Modifications will become effective on the day they are posted unless statedotherwise.</p>
<p>Your continued use of our Services after changes become effective shall mean that you accept those changes. If you do not accept the amended Terms, you should refrain from using the Platform. Any revised Terms shall supersede all previous Terms.</p>
<p>With regard to an Agreement other than of a continuous nature, the amended Terms shall not in any way affect the rights acquired prior to the effective date of the amendments to the Terms.</p>
<p>If an amendment to the Terms would result in the introduction of any additional fees for the use of the Platform during the duration of a specific Subscription, a User may exercise their right to withdraw from an Agreement.</p>
<p><b>12. How to Contact Us</b></p>
<p>The best way to get in touch with us is to contact our Support Team (contact@aidify.eu). We’d love to hear your questions, concerns, and feedback about our Services.</p>
`,
      b2c: `
<p>These Terms of Use (“Terms”), as amended and restated on 12 Dec. 23, set out the terms, scope and conditions of use of the services ("Services") provided via a Learning Experience Platform available at aidify.eu (“Platform”) owned by Aidify Sp. z o.o. with its registered office in Lublin (ZIP code: 20-418), ul. Nowy Świat 11A, entered into the Register of the National Court Register kept by the District Court Lublin-Wschód in Świdnik, 6th Commercial Division under NCR number: 0000791766 (“AIDIFY”).</p>
<p>The Platform contains e-learning courses and additional content and materials available in electronic form, in accordance with the choice of service made by you as a user of the Platform</p>
<p>These Terms apply to a user of the Platform (“User”), being a consumer or an entrepreneur having the rights of a consumer (jointly “Consumers” and any of them “Consumer”), i.e. an individual and concluding an agreement for Services between them as a User and AIDIFY (“Agreement”), the subject matter of which is not directly related to the business or professional activity a User carries out, or the subject matter of which is directly related to a User’s business activity, but from the content of that Agreement it follows that it does not have a professional character for a User, arising in particular from the subject matter of the business activity that User carries out, made available on the basis of the provisions on the Central Register and Information on Business Activity, as well as all their activities on the Platform.</p>
<p>By using our Services available through the Platform you accept these Terms and you are obliged to comply with the rules contained herein. By using the Platform, you accept the provisions of these Terms.</p>
<p>We also provide details regarding our processing of personal data of our Users in our Privacy Policy.</p>
<p><b>1. Accounts</b></p>
<p>You need an account for most activities on our Platform, including to access the content.</p>
<p>When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address and you must accept these Terms. An account is linked to an e-mail address provided during the User’s registration (account creation).</p>
<p>You gain access to the full functionality of the Platform according to the selected Services after activation of your account by AIDIFY, of which you are informed by e-mail.</p>
<p>As a registered User you may modify/delete your data contained in the “Profile” tab at any time in accordance with our Privacy Policy. AIDIFY is not responsible for the accuracy of a User's data provided during registration.</p>
<p>AIDIFY has the right to refuse to register any User and/or to block the account of a User who violates the provisions of these Terms. In such a case, the User will not be entitled to compensation for lack of access to the selected Services.</p>
<p>You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. You must protect the password and login in such a way that it cannot be made known to third parties. You may not transfer your account to someone else or use someone else’s account. You may not share your account login credentials as well as any content available through the Platform with anyone else (in particular, but not only, it is prohibited to display it by any means, e.g. an overhead projector). You are responsible for what happens with your account.</p>
<p>In the event of a breach of the above provisions, you are entirely responsible for the actions of such third parties, and AIDIFY as the Platform’s administrator shall be entitled to block your access to the Platform. In the event that access to the Platform is blocked for the reasons set out in this paragraph, you are not entitled to claim a refund of the fee paid.</p>
<p>If you contact us to request access to an account, we will not grant you such access unless you can provide us with the information that we need to prove you are the owner of that account. In the event of the death of a User, the account of that user will be closed.</p>
<p>You must notify us immediately upon learning that someone else may be using your account without your permission (or if you suspect any other breach of security) by contacting our Support Team (contact@aidify.eu). We may request some additional verification from you to confirm that you are indeed the owner of your account.</p>
<p>You can terminate your account at any time by contacting our Support Team (contact@aidify.eu).</p>
<p><b>2. Conclusion of an agreement for Services</b></p>
<p>Agreement is concluded by the User placing an order for granting an access to the selected Services through the Platform, i.e. making a declaration of intent to use the selected Services through the Platform.</p>
<p>You may place an order 24 hours a day via the Platform.</p>
<p>By placing an order, you confirm the authenticity and factual accuracy of the data contained in the message and agree to the provisions of these Terms, acknowledging their binding nature</p>
<p>The order is considered to be accepted when AIDIFY sends you information confirming the acceptance of the order via electronic correspondence.</p>
<p>You will be granted access to the ordered Services upon payment, within five days at the latest. An email will be sent to the email address indicated in the application informing you that the Services have been made available on the Platform.</p>
<p><b>3. Content Enrollment</b></p>
<p>Access to the Platform is time-limited. The materials placed on the Platform are subject to copyright in accordance with the Act of 4 February 1994 on Copyright and Related Rights (Journal of Laws 1994 no. 24 item 83).</p>
<p>AIDIFY grants you (as a User) a limited, non-exclusive, non-transferable license to access and view the content for which you have paid all required fees, solely for your personal, non-commercial, educational purposes through the Services, in accordance with these Terms and any conditions or restrictions associated with the particular content or feature of our Services. All other uses are expressly prohibited. You may not copy, reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, sublicense, or otherwise transfer or use any content unless we give you explicit permission to do so in a written agreement signed by an AIDIFY authorized representative.</p>
<p>We reserve the right to revoke any license to access and use any content at any point in time in the event that we decide or are obligated to disable access to the content due to legal or policy reasons, for example, if the course or other content you enrolled in is the object of a copyright complaint.</p>
<p><b>4. Payments</b></p>
<p>When you make a payment, you agree to use a valid payment method.</p>
<p><b>4.1. Pricing</b></p>
<p>The prices of content and features available through the Platform are determined based on the following terms.</p>
<p>The price applicable to the content and features available through the Platform which You have chosen will be the price at the time you complete your purchase of the content (at checkout).</p>
<p>AIDIFY reserves the right to introduce promotional campaigns from time to time. Details of the promotional action will be set out in the separate terms and conditions in each case.</p>
<p><b>4.2. Payments</b></p>
<p>You agree to pay the fees for content that you purchase, and you authorize us to charge your debit or credit card or process other means of payment for those fees (provided that such payment option is available by the time You make a purchase). AIDIFY works with payment service providers to offer you the most convenient payment methods and to keep your payment information secure. We may update your payment methods using information provided by our payment service providers. Check out our Privacy Policy for more details.</p>
<p>When you make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails, AIDIFY is not obliged to provide access services to the Platform. If for any reason you still get access to the Platform, you agree to pay us the corresponding fees within seven (7) days of notification from us. We reserve the right to disable access to any content for which we have not received adequate payment.</p>
<p><b>5. Technical requirements</b></p>
<p>The Platform is accessible exclusively via the Internet and operates 7 days a week, 24 hours a day, excluding periods necessary for modernisation work or excluding periods of failure. The technical computer requirements for the correct use of the resources of the Platform are:</p>
<ol type="a">
  <li>access to the Internet;</li>
  <li>current browser: Mozilla Firefox, Safari, Microsoft Edge, Opera, Chrome;</li>
  <li>headphone jack or speakers;</li>
  <li>an active e-mail account.</li>
</ol>
<p>The use of all the possibilities of the Platform requires the activation of cookies.</p>
<p>In the event of any problems related to the functioning of the Platform, you should contact our Support Team (contact@aidify.eu), who will take the appropriate steps to enable you to use the Platform correctly and participate in the training courses.</p>
<p>AIDIFY is not responsible for inadequate configuration of a User's computer. AIDIFY is not responsible for cases where a User fails to participate in the training due to hardware problems with the User’s computer.</p>
<p><b>6. Content and Behavior Rules</b></p>
<p>You can only use AIDIFY for lawful purposes. You’re responsible for all the content that you post on our Platform. You should keep the reviews, questions, posts, courses and other content you upload in line with the law, and respect the intellectual property rights of others. We can ban your account for repeated or major offenses. If you think someone is infringing your copyright on our Platform, let us know.</p>
<p>You may not access or use the Services or create an account for unlawful purposes. Your use of the Services and behavior on our Platform must comply with applicable laws or regulations.</p>
<p>If you are a User, the Services enable you to ask questions to the subject matter experts (“SME”) of courses or other content you are enrolled in, and to post reviews of content. Don’t post or submit anything that is not yours.</p>
<p>If we are put on notice that your course or content violates the law or the rights of others (for example, if it is established that it violates intellectual property or image rights of others, or is about an illegal activity), if we believe your content or behavior is unlawful, inappropriate, or objectionable (for example if you impersonate someone else), we may remove your content from our Platform. AIDIFY complies with copyright laws.</p>
<p>AIDIFY has discretion in enforcing these Terms. We may restrict or terminate your permission to use our Platform and Services or ban your account at any time, with or without notice, for any violation of these Terms, for fraudulent chargeback requests, upon the request of law enforcement or government agencies, or if we suspect that you engage in fraudulent or illegal activities. Upon any such termination we may delete your account and content, and we may prevent you from further access to the Platform and use of our Services. Your content may still be available on the Platform even if your account is terminated or suspended. You agree that in case of violation of these Terms we will have no liability to you or any third party for termination of your account, removal of your content, or blocking of your access to our Platform and Services.</p>
<p>If a User has published content that infringes your copyright or trademark rights, please let us know.</p>
<p><b>7 Using AIDIFY at Your Own Risk</b></p>
<p>Our Platform model means that except for the courses and supplementary materials created by our SMEs and available on the Platform, we do not review or edit any other content for legal issues, and we are not in a position to determine the legality of the users’ submitted content. We do not exercise any editorial control over the users’ submitted content that is available on the Platform (except for the above mentioned courses and supplementary materials created by our SMEs) and, as such, do not guarantee in any manner the reliability, validity, accuracy, or truthfulness of that content. If you access such content, you rely on any information provided by any user at your own risk.</p>
<p>By using the Services, you may be exposed to content that you consider offensive, indecent, or objectionable. AIDIFY has no responsibility to keep such content from you and no liability for your access or enrollment in any course or other content, to the extent permissible under applicable law. This also applies to any content relating to health, wellness, and physical exercise. You acknowledge the inherent risks and dangers in the strenuous nature of these types of content, and by accessing such content you choose to assume those risks voluntarily, including risk of illness, bodily injury, disability, or death. You assume full responsibility for the choices you make before, during, and after your access to the content.</p>
<p>We are not responsible or liable for any interactions involved between SMEs (subject matter experts) and Users. We are not liable for disputes, claims, losses, injuries, or damage of any kind that might arise out of or relate to the conduct of SMEs or Users.</p>
<p>When you use our Services, you will find links to other websites that we don’t own or control. We are not responsible for the content or any other aspect of these third-party sites, including their collection of information about you. You should also read their terms and conditions and privacy policies.</p>
<p><b>8. AIDIFY’s Rights</b></p>
<p>We own the Platform and Services, including the website, present or future apps and services, and things like our logos, API (Application Programming Interface), code, and content created by our employees. You can’t tamper with those or use them without authorization.</p>
<p>All rights, title, and interest in and to the Platform and Services, including our website, our existing or future applications, our APIs, databases, and the content our employees or partners submit or provide through our Services (but excluding content provided by Users) are and will remain the exclusive property of AIDIFY  and its licensors. Our Platform and Services are protected by copyright, trademark, and other laws. Nothing gives you a right to use the AIDIFY name or any of the AIDIFY trademarks, logos, domain names, and other distinctive brand features without our prior explicit consent to do so. Any feedback, comments, or suggestions you may provide regarding AIDIFY or the Services is entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you.</p>
<p>You may not do any of the following while accessing or using the Platform and Services:</p>
<ol type="a">
  <li>access, tamper with, or use non-public areas of the Platform (including content storage), AIDIFY’s computer systems, or the technical delivery systems of AIDIFY’s service providers;</li>
  <li>browsing and analysing the Platform, its features, and available content in order to create or develop a competitive solution to the Platform or activity in the same business area;</li>
  <li>disable, interfere with, or try to circumvent any of the features of the Platform related to security or probe, scan, or test the vulnerability of any of our systems;</li>
  <li>copy, modify, create a derivative work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of or content on the Platform or Services;</li>
  <li>access or search or attempt to access or search our Platform by any means (automated or otherwise) other than through our currently available search functionalities that are provided via our website, mobile apps, or API (and only pursuant to those API terms and conditions). You may not scrape, spider, use a robot, or use other automated means of any kind to access the Services;</li>
  <li>in any way use the Services to send altered, deceptive, or false source-identifying information (such as sending email communications falsely appearing as AIDIFY); or</li>
  <li>interfere with, or disrupt, (or attempt to do so), the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Platform or Services, or in any other manner interfering with or creating an undue burden on the Services.</li>
</ol>
<p><b>9. Subscription Terms</b></p>
<p>This section covers additional terms that apply to your use of our subscription-based collections as a User (“Subscription Plans”). By using a Subscription Plan, you agree to the additional terms in this section.</p>
<p><b>9.1. Subscription Plans</b></p>
<p>During your subscription to a Subscription Plan, you get a limited, non-exclusive, non-transferable license from us to access and view the content included in that Subscription Plan via the Services.</p>
<p>The subscription that you purchase or renew determines the scope, features, and price of your access to a Subscription Plan. You may not transfer, assign, or share your subscription with anyone else.</p>
<p>We reserve the right to revoke any license to use the content in our Subscription Plans for legal or policy reasons at any time and at our sole discretion, such as if we no longer have the right to offer the content through a Subscription Plan. </p>
<p><b>9.2. Account Management</b></p>
<p>You may cancel your subscription by following the steps outlined in our User Manual. If you cancel your subscription to a Subscription Plan, your access to that Subscription Plan will automatically end on the last day of your billing period. On cancellation, you will not be entitled to receive a refund or credit of any fees already paid for your subscription, unless otherwise required by applicable law. For clarity, cancellation of a subscription does not terminate your AIDIFY account.</p>
<p><b>9.3. Free Trials & Renewals</b></p>
<p>Your subscription may start with a free trial. The duration of the free trial period of your subscription is 14 days during which you will have access to the Platform and Users are entitled to a total of 2 hours to watch video courses and engage in practice activities. You may only use a free trial once. When you attempt to use a free trial, you will be notified whether you are eligible to use a free trial.</p>
<p>In order to use a free trial, the User should tick the option when completing the order form: "Activate Freemium", then complete the subsequent steps of the interactive form or contact AIDIFY in any other way. The Agreement (with respect to a free trial) is concluded at the moment of accepting the Terms during the registration to the AIDIFY Platform.</p>
<p>AIDIFY determines free trial eligibility at our sole discretion and may limit access, eligibility, or duration. We reserve the right to terminate the free trial and suspend your subscription if we determine that you aren’t eligible.</p>
<p>If the User has not paid the subscription price by the end of a free trial period, at the end of the free trial period the Agreement is terminated and AIDIFY is not obliged to continue to provide access services to the Platform.</p>
<p><b>9.4. Payments and Billing</b></p>
<p>The subscription fee will be listed at the time of your purchase. You can visit our User Manual to learn more about where to find the fees and dates applicable to your subscription. Payments are non-refundable and there are no refunds or credits for partially used periods, unless otherwise required by applicable law.</p>
<p>To subscribe to a Subscription Plan, you must provide a payment method. By subscribing to a Subscription Plan and providing your billing information during checkout, you grant us and our payment service providers the right to process payment for the then-applicable fees via the payment method we have on record for you.</p>
<p>In the event that we update your payment method using the information provided by our payment service providers (as described in the “Payments” section above), you authorize us to continue to charge the then-applicable fees to your updated payment method.</p>
<p>If we are unable to process payment through the payment method we have on file for you, or if you file a chargeback disputing charges made to your payment method and the chargeback is granted, we may suspend or terminate your subscription.</p>
<p>We reserve the right to change our Subscription Plans or adjust pricing for our Services at our sole discretion. Any price changes or changes to your subscription will take effect following notice to you from a new subscription term, each time subject to the provisions of Clause 14 of this Terms. </p>
<p><b>9.5. Subscription Disclaimers</b></p>
<p>At any point in the future, we reserve the right to offer or cease to offer additional features to any Subscription Plan, or to otherwise modify or terminate a Subscription Plan at our sole discretion (none of these shall in any way affect the rights acquired prior to the effective date of such modification or termination). We have no responsibility to preserve or store the content inputted by you in connection with your use of any Subscription Plan. These disclaimers are in addition to those listed in the “Disclaimers” section below.</p>
<p><b>10. Withdrawal from an Agreement</b></p>
<p>If you have not started using the Platform. i.e. you have not logged in to the Platform after you have paid for your subscription to the Platform, you may withdraw from an Agreement without giving any reason within 14 days from the date of its conclusion, obtaining a 100% refund.</p> 
<p>You may withdraw from an Agreement by sending an e-mail stating your wish to withdraw to our Support Team (contact@aidify.eu). It is sufficient to meet the deadline indicated in this section above by sending the declaration of withdrawal by e-mail to our Support Team before its expiry. You may use the form - Declaration of withdrawal set out in the Schedule to these Terms. The use of the form proposed by AIDIFY is not obligatory and does not affect the exercise of the right to withdraw from an Agreement by a User.</p>
<p>AIDIFY shall immediately, within a period not exceeding 14 calendar days from the date of receipt of the declaration of withdrawal, reimburse the payment using the same means of payment which you have used, unless you have expressly agreed to a different method of reimbursement that does not incur any costs for you.</p>
<p>By starting to use the Platform you lose the right to withdraw from an Agreement.</p> 
<p><b>11. Claims</b></p>
<p>AIDIFY shall undertake actions to ensure the fully correct functioning of the Platform to the extent resulting from the current technical knowledge and undertakes to remove all irregularities reported by the Users within a reasonable period of time. A User is obliged to immediately notify AIDIFY of any irregularities or interruptions in the functioning of the Services.</p>
<p>As a User you have the right to lodge a complaint regarding the provision of the Services. If the data or information provided in the complaint needs to be supplemented, before the complaint is considered, AIDIFY shall request a User submitting the complaint to supplement them in the indicated scope.</p>
<p>Complaints should be submitted electronically via our Support Team (contact@aidify.eu).</p>
<p>A User's complaint shall be considered within 14 days.</p>
<p>A User shall be informed about the manner of resolving the complaint by e-mail or telephone, unless another method of providing such information has been agreed with a User.</p>
<p>AIDIFY reserves the right to intervene in the technical structure of a User's account in order to diagnose irregularities in the functioning of the Services, as well as to make changes and influence in any other way the technical side of the account in order to modify it or to restore the proper functioning of the account itself or the Services.</p>
<p><b>12. Miscellaneous Legal Terms</b></p>
<p><b>12.1. Binding Agreement</b></p>
<p>You agree that by registering, accessing, or using our Services, you are agreeing to enter into a legally binding contract with AIDIFY. If you do not agree to these Terms, do not register, access, or otherwise use any of our Services.</p>
<p>Any version of these Terms in a language other than English is provided for convenience and you understand and agree that the English language will control if there is any conflict.</p>
<p>These Terms (including any agreements and policies linked to these Terms) constitute the entire agreement between you and us.</p>
<p>If any part of these Terms is found to be invalid or unenforceable by applicable law, then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms will continue in effect.</p>
<p><b>12.2. Disclaimers</b></p>
<p>The Services and their content are provided on an “as is” and “as available” basis. We (and our affiliates, suppliers, partners, and agents) make no representations or warranties about the suitability, reliability, availability, timeliness, security, lack of errors, or accuracy of the Services or their content, and expressly disclaim any warranties or conditions (express or implied), including implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, unless such representations or warranties are explicitly given under an Agreement. We (and our affiliates, suppliers, partners, and agents) make no warranty that you will obtain specific results from use of the Services. Your use of the Services (including any content) is entirely at your own risk.</p>
<p>We may decide to cease making available certain features of the Services at any time and for any reason. Under no circumstances will AIDIFY or its affiliates, suppliers, partners or agents be held liable for any damages due to such interruptions or lack of availability of such features.</p>
<p>We are not responsible for delay or failure of our performance of any of the Services caused by events beyond our reasonable control, like an act of war, hostility, or sabotage; natural disaster; electrical, internet, or telecommunication outage; or government restrictions.</p>
<p><b>12.3. Limitation of Liability</b></p>
<p>AIDIFY shall not be liable for the occurrence on the part of a User of any damage caused by the User's use of, or inability to use, the Platform, including loss of data, delays in receiving data or delays in the transmission of data due to lack of transmission, incorrect transmission, delays or other interruptions in the provision of Services caused by factors beyond AIDIFY’s control.</p>
<p>Factors beyond AIDIFY’s control are considered to be, in particular:</p>
<ol type="a">
  <li>failures of transmission or telephone lines (including international ones), load on the lines, lack of access to the networks of national and foreign operators or irregularities in the operation of such telecommunications operators,</li>
  <li>interruptions in the supply of electricity and failures of technical equipment and other such circumstances for which service providers and telecommunications operators are responsible or force majeure, understood as a sudden, unexpected external cause for which AIDIFY is not responsible,</li>
  <li>necessary maintenance, repair and other interruptions of a technical nature.</li>
</ol>
<p>AIDIFY reserves the right to shut down the Platform in whole or in part for an indefinite period of time in order to modernise or repair it.</p>
<p><b>12.4. Governing Law and Jurisdiction</b></p>
<p>These Terms are governed by the laws of Poland.</p>
<p><b>12.5. No Assignment</b></p>
<p>You may not assign or transfer these Terms (or the rights and licenses granted under them). For example, if you registered an account as an employee of a Company, your account cannot be transferred to another employee. We may assign these Terms (or the rights and licenses granted under them) to another company or person without restriction. Nothing in these Terms confers any right, benefit, or remedy on any third-party person or entity. You agree that your account is non-transferable and that all rights to your account and other rights under these Terms terminate upon your death.</p>
<p><b>12.6. Out-of-court Dispute Resolution</b></p>
<p>As a Consumer, you have the right to make use of out-of-court dispute resolution. Information on the possibility of exercising such rights and the relevant procedures is available at the offices and websites of municipal (district) consumer ombudsmen, social organisations whose statutory tasks include consumer protection, Provincial Inspectorates of Trade Inspection and at the website of the Office of Competition and Consumer Protection:</p>
<ul>
  <li>http://www.uokik.gov.pl/spory_konsumenckie.php</li>
  <li>http://www.uokik.gov.pl/sprawy_indywidualne.php</li>
  <li>http://www.uokik.gov.pl/wazne_adresy.php</li>
</ul>
<p>There are various possibilities for the Users who are Consumers to use out-of-court means of dispute resolution, including:</p>
<ol type="a">
  <li>requesting the resolution of a dispute arising from a concluded Agreement to a permanent amicable consumer court referred to in Art. 37 of the Act of 15 December 2000 on Trade Inspection (Journal of Laws 2001 No. 4, item 25, as amended), the rules of organisation and operation of permanent amicable consumer courts are specified in the Regulation of the Minister of Justice of 25 September 2001 on defining the rules of organisation and operation of permanent amicable consumer courts. (Journal of Laws 2001 No. 113, item 1214, as amended),</li> 
  <li>applying to the provincial inspector of the Commercial Inspection to initiate mediation proceedings for an amicable settlement of the dispute between a Consumer and AIDIFY,</li>
  <li>use the assistance of a district (city) consumer ombudsman or a social organisation whose statutory tasks include consumer protection (e.g. Consumer Federation),</li> 
  <li>use an electronic method of dispute resolution with AIDIFY through the EU platform available at: https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=PL</li>
</ol>
<p><b>13. Updating These Terms</b></p>
<p>From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and AIDIFY reserves the right in its sole discretion to modify and/or make changes to these Terms at any time.</p>
<p>If we make any change, we will notify you using prominent means, such as by email notice sent to the email address specified in your account, at least 14 days before the updated version comes into force. Modifications will become effective on the day they are posted unless statedotherwise.</p>
<p>Your continued use of our Services after changes become effective shall mean that you accept those changes. If you do not accept the amended Terms, you should refrain from using the Platform. Any revised Terms shall supersede all previous Terms.</p>
<p>With regard to an Agreement of a continuous nature concluded on the basis of these Terms, the amended Terms shall be binding upon a User, provided that the requirements specified in art. 384 and 384[1] of the Civil Code have been complied with.</p>
<p>With regard to an Agreement other than of a continuous nature, the amended Terms shall not in any way affect the rights acquired prior to the effective date of the amendments to the Terms.</p>
<p>If an amendment to the Terms would result in the introduction of any additional fees for the use of the Platform during the duration of a specific Subscription, a User may exercise their right to withdraw from an Agreement.</p>
<p><b>14. How to Contact Us</b></p>
<p>The best way to get in touch with us is to contact our Support Team (contact@aidify.eu). We’d love to hear your questions, concerns, and feedback about our Services.</p>
`,
    },
    cookiesPolicy: `
<p><i>Last updated on 01.08.2023</i></p>
<p>We use cookies to provide a better, faster and safer use of services AIDIFY provides. This Cookie Policy sets out the rules for the use of these technologies. For further details on how we process your personal data as well as ways you may contact us, please consult our Privacy Policy.</p>
<p><b>1. What are cookies?</b></p>
<p>Cookies are small packets of computer data, usually in text form, which are stored on your device. Their purpose is to ensure more efficient, effective but also comfortable user experience while using our services. They make it possible to identify the device and the browser used to use the services, or to obtain and store other data about user activity. Cookies do not cause any configuration changes to your device or its software.</p>
<p><b>1.1. How long cookies are stored on your device?</b></p>
<p>Cookies may be stored on the user's device:</p>
<ul>
  <li>For a session i.e. until the user logs out, leaves the service or switches off the internet browser;</li>
  <li>Until the particular expiry date assigned to a cookie; or</li>
  <li>Until they are deleted by the user, if applicable.</li>
</ul>
<p><b>1.2. What are different categories of cookies?</b></p>
<p>Depending on their function the cookies may be:</p>
<ul>
  <li><b>Essential</b> cookies are strictly necessary for the proper functioning of the services and cannot be disabled through service settings. Those are typically used to enable basic functions, such as setting privacy options, logging in, navigating the service or filling in forms. They can only be disabled by changing your browser settings;</li>
  <li><b>Functional.</b> Those make it possible to remember settings selected by users and to personalise the services, e.g. with regard to the language choice or set-up of the account</li>
  <li><b>Analytical (performance)</b> cookies enable us to understand how individual users interact with our services, and consequently improve their performance, by collecting and analysing information about the sources of users’ traffic;</li>
  <li><b>Advertising</b> cookies serve to promote certain services or content. They allow the content displayed to be tailored to users' preferences.</li>
</ul>
<p><b>2. What cookies do we use?</b></p>
<table>
  <tr><th>Cookie category</th><th>Cookie name</th><th>Purpose</th><th>Storage period</th><th>Provider</th></tr>
  <tr><td>Essential</td><td>jwttoken</td><td>JSON web token for technical authentication of the user, used in most of the calls to internal API</td><td>24 hours or session (depends on “remember me” checkbox setting)</td><td>internal</td></tr>
  <tr><td rowspan="3">Analytical</td><td>_ga</td><td>Used to distinguish users.</td><td>2 years</td><td>Google Analytics</td></tr>
  <tr><td>_gid</td><td>Used to distinguish users.</td><td>24 hours</td><td>Google Analytics</td></tr>
  <tr><td>_gat</td><td>Used to throttle request rate.</td><td>1 minute</td><td>Google Analytics</td></tr>
</table>
<p><b>3. How to disable cookies?</b></p>
<p>You can choose whether you want functional, analytical and advertising cookies to be used through the options on the banner while you visit the page. However, please be advised that restricting savingcookies may impair the quality of the user experience, as it will eliminate the customisation of the services. It may also prevent the user from saving customised settings, e.g. login data.</p>
<p><b>3.1. Choice and consent withdrawal</b></p>
<p>At any given moment you may edit the cookie settings from the login page by withdrawing your consent for some or all optional categories.</p>
<p><b>3.2. Browser settings</b></p>
<p>Most browsers allow you to control cookies through settings that can be adjusted to reflect your consent to the use of cookies as well as to view and delete cookies. To change your browser settings, please consult the documentation provided by a provider whose browser you use. To learn more on how you can control cookies you may visit: https://cookiepedia.co.uk/how-to-manage-cookies. In addition, your wireless device may also allow you to control whether location or other data is collected and shared.</p>
`,
  },
};

const PL = {};

export const languages = {
  EN,
  PL,
};
