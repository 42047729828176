import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../../dummyData/dummyData";
import SetableCompetence from "../SetableCompetence/SetableCompetence";
import styles from "./SetableCompetencesList.module.scss";
import Text from "../../atoms/Text/Text";
import Button from "../../atoms/Button/Button";
import FilteredList from "../FilteredList/FilteredList";
import { FilterContext } from "../../../contexts/FilterContext";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";

const filterDefinitions = [
  {
    keyPath: "fastFilters",
    header: languages.EN.labels.inYourJobPosition,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "learningArea",
    header: languages.EN.labels.learningArea,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "visibility",
    header: languages.EN.labels.visibility,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "group.name",
    header: languages.EN.labels.group,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "country",
    header: languages.EN.labels.region,
    typeOfFilter: "checkbox",
  },
  {
    keyPath: "name",
  },
];

const SetableCompetencesList = ({ userCompetences, allCompetences, saveLabel, onSave, allowManualVerification }) => {
  const { useSelectables, useProcessedData, useFilterCriteria, useFilterReset } = useContext(FilterContext);
  const { callPopup } = useContext(PopupsContext);
  const { selected, setSelected } = useSelectables;
  const { processedData, isDataReady, setIsDataReady } = useProcessedData;
  const { filterCriteria } = useFilterCriteria;
  const { setFilterReset } = useFilterReset;
  const [userCompetencesPreparedData, setUserCompetencesPreparedData] = useState([]);
  const [availableCompetencesPreparedData, setAvailableCompetencesPreparedData] = useState([]);
  const [addMore, setAddMore] = useState(true);
  const [hasEnoughCompetences, setHasEnoughCompetences] = useState(false);

  useEffect(() => {
    if (userCompetences && allCompetences) {
      setSelected(allCompetences.map(item => {
        const userCompetence = userCompetences.find(userCompetence => userCompetence.competence.id === item.id);

        return {
          id: item.id,
          goal: userCompetence ? userCompetence.goal : 1,
          level: userCompetence?.level,
          type: userCompetence?.type,
        }
      }));
    }
  }, [userCompetences, allCompetences]);

  useEffect(() => {
    const userCompetenceIds = selected
      .filter(item => item.level !== undefined)
      .map(item => item.id);

    setHasEnoughCompetences(userCompetenceIds.length >= 5);
    setUserCompetencesPreparedData(allCompetences
      .filter(item => userCompetenceIds.includes(item.id))
      .map(item => {
        const userCompetence = userCompetences.find(userCompetence => userCompetence.competence.id === item.id);

        return {
          ...item,
          ...userCompetence,
          learningArea: languages.EN.enums.learningAreas[item.topic],
          visibility: languages.EN.enums.visibility[item.organization ? 2 : 3],
        }
      })
      .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
    );
    setAvailableCompetencesPreparedData(allCompetences
      .filter(item => !userCompetenceIds.includes(item.id))
      .map(item => ({
        ...item,
        fastFilters: item.competence_levels.filter(levelData => levelData.type === 0).length > 0 ? [languages.EN.labels.keyCompetences] : [],
        learningArea: languages.EN.enums.learningAreas[item.topic],
        visibility: languages.EN.enums.visibility[item.organization ? 2 : 3],
      }))
      .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
    );
    setIsDataReady(true);
  }, [selected]);

  useEffect(() => {
    if (filterCriteria.find(criterion => criterion.path === 'fastFilters') !== undefined) {
      setSelected(selected.map(selectedItem => {
        const keyCompetenceLevel = allCompetences
          .find(competence => competence.id === selectedItem.id)
          .competence_levels
          .find(levelData => levelData.type === 0);

        return keyCompetenceLevel !== undefined ? { ...selectedItem, goal: keyCompetenceLevel.level } : selectedItem;
      }));
    }
  }, [filterCriteria]);

  return (
    <>
      <div className={styles.userCompetences}>
        <div className={styles.userCompetences__title}>
          <Text s20 lh24 w800 secondary>
            {languages.EN.labels.competencesAssignedToYou}
          </Text>
        </div>
        {!userCompetencesPreparedData.length && (
          <>
            <Text s30 lh32 dark-2 w600>
              {languages.EN.messages.useSection}
            </Text>
            <Text s30 lh32 dark-2 w600>
              {languages.EN.messages.youHaveToSelect5Competences}
            </Text>
          </>
        )}
        <div className={styles.ourCompetence}>
          {userCompetencesPreparedData.map((preparedItem, i) => (
            <SetableCompetence
              key={i}
              data={preparedItem}
              allowManualVerification={allowManualVerification}
            />
          ))}
        </div>
      </div>
      <div className={styles.stepButtons}>
        <Button variant={"primaryW100"} onClick={
          hasEnoughCompetences ?
            () => onSave(selected.filter(selectedItem => selectedItem.level !== undefined)) :
            () => callPopup({
              type: ACTIONS.CONFIRM,
              payload: {
                header: languages.EN.labels.warning,
                content: languages.EN.messages.notEnoughCompetences,
                action: () => onSave(selected.filter(selectedItem => selectedItem.level !== undefined)),
              },
            })
        }>
          {saveLabel}
        </Button>
      </div>
      <section data-active={addMore} className={styles.addMore}>
        <span className={styles.addMore__button}>
          <Text s20 lh24 w800 secondary>
            {languages.EN.labels.addMore}
          </Text>
          <Button
            variant="strokeIcon"
            onlyIcon
            icon={addMore ? "topArrow" : "bottomArrow"}
            onClick={() => setAddMore(!addMore)}
          />
        </span>
        {addMore && (
          <FilteredList
            id="userCompetences"
            data={isDataReady ? availableCompetencesPreparedData : undefined}
            filterDefinitions={filterDefinitions}
            RenderTileComponent={SetableCompetence}
            gridSize={2}
            buttons={[
              {
                icon: "plus",
                label: languages.EN.labels.addAllFiltered,
                onClick: () => {
                  const filteredItemIds = processedData.map(item => item.id);

                  setSelected(selected.map(selectedItem => filteredItemIds.includes(selectedItem.id) ? { ...selectedItem, level: 0, type: 0 } : selectedItem));
                  setIsDataReady(false);
                  setFilterReset(true);
                },
              },
            ]}
          />
        )}
      </section>
    </>
  );
};

export default SetableCompetencesList
