import React, { useContext, useEffect, useMemo, useState } from "react";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { languages } from "../../../../dummyData/dummyData";
import Table, { ROW_VARIANT_ERROR, ROW_VARIANT_WARNING } from "../Table";
import { TYPE_CHECKBOX, TYPE_DATE, TYPE_DROPDOWN, TYPE_MULTICOMPLETE, TYPE_NUMBER } from "../../../molecules/Form/Form";
import { licenseControlRepository } from "../../../../repository/licenseControl.repository";
import styles from "../Table.module.scss";
import moment from "moment";
import Button from "../../../atoms/Button/Button";
import { organizationLicensesRepository } from "../../../../repository/organizationLicenses.repository";
import Text from "../../../atoms/Text/Text";
import { currencyFormat, dateFormat } from "../../../../utils/formatters";
import { organizationRepository } from "../../../../repository/organization.repository";
import useFetch from "../../../../hooks/useFetch";
import { PostContext } from "../../../../contexts/PostContext";
import TextTruncated from "../../../atoms/TextTruncated/TextTruncated";
import { coursesRepository } from "../../../../repository/courses.repository";

const licensePopupFields = (collections = [], courses = [], data = {}) => {
  return [
    {
      name: "type",
      label: languages.EN.labels.type,
      required: true,
      type: TYPE_DROPDOWN,
      value: data.type,
      items: languages.EN.enums.licensesTypes,
    },
    {
      name: "number",
      label: languages.EN.labels.noOfLicenses,
      required: true,
      type: TYPE_NUMBER,
      value: 1,
    },
    {
      name: "content",
      label: languages.EN.labels.includedContent,
      required: data.type !== '1',
      disabled: data.type === '1',
      type: TYPE_MULTICOMPLETE,
      items: courses.map(course => ({
        id: `course_${course.id}`,
        label: `[${languages.EN.labels.course}] ${course.display_name}`
      })).concat(collections.map(collection => ({
        id: `collection_${collection.id}`,
        label: `[${languages.EN.labels.collection}] ${collection.name}`,
      }))),
    },
    {
      name: "expiry_date",
      label: languages.EN.labels.expiryDate,
      required: true,
      type: TYPE_DATE,
      value: data.expiry_date ?? "",
    },
    [
      {
        name: "price",
        label: languages.EN.labels.licensePrice,
        required: !data.is_trial,
        disabled: data.is_trial,
        type: TYPE_NUMBER,
        value: data.price,
      },
      {
        name: "is_trial",
        label: languages.EN.labels.isFreemium,
        type: TYPE_CHECKBOX,
        value: data.is_trial ?? 0,
      },
    ],
  ]
};

const newUserPopupFields = (data = {}, forPlatformOwner = true) => ([
  {
    name: "number",
    label: languages.EN.labels.licenseNumber,
    disabled: true,
    value: data[forPlatformOwner ? 'number' : 'name']
  },
  {
    name: "email",
    label: languages.EN.labels.email,
    required: true,
    value: data.email,
  },
]);

const getContentText = (data) => {
  let text = `${data.courses.length} ${languages.EN.labels.courses.toLowerCase()} `;

  if (data.is_mini) {
    text += `(${languages.EN.labels.miniSubscription})`;
  } else {
    text += `(${data.collections.length} ${languages.EN.labels.collections.toLowerCase()})`;
  }

  return text;
}

const platformOwnerColumns = [
  {
    Header: languages.EN.labels.licenseNumber,
    accessor: "number",
    rowSpan: 2,
  },
  {
    Header: languages.EN.labels.includedContent,
    accessor: "content",
    Cell: (data) => data.row.original.type === 0 ? (
      <TextTruncated lineClamp={1} style={{ width: "800px" }}>
        <Text>{getContentText(data.row.original)}</Text>
        <br />
        <br />
        {data.row.original.courses
          .map(course => (
            <div key={course.id}>
              <Text w800>{course.handle}</Text>: {course.display_name}
            </div>
          ))}
      </TextTruncated>
    ) : languages.EN.placeholders.empty,
    rowSpan: 2,
    cellStyle: { width: "250px" },
  },
  {
    Header: languages.EN.labels.price,
    accessor: (data) => data.is_trial ? languages.EN.labels.freemium.toUpperCase() : currencyFormat(data.price),
    rowSpan: 2,
  },
  {
    Header: languages.EN.labels.expiryDate,
    accessor: (data) => dateFormat(data.expiry_date),
    rowSpan: 2,
  },
  {
    Header: languages.EN.labels.assignedEmployee,
    disableSortBy: true,
    headerStyle: { justifyContent: "center" },
    columns: [
      {
        Header: languages.EN.labels.name,
        accessor: "employeeName",
      },
      {
        Header: languages.EN.labels.email,
        accessor: "employeeEmail",
      },
    ],
  },
  {
    Header: languages.EN.labels.assignedOn,
    accessor: (data) => data.assigned ? dateFormat(data.assigned) : languages.EN.placeholders.empty,
    rowSpan: 2,
  },
];

const administratorColumns = [
  {
    Header: languages.EN.labels.licenseNumber,
    accessor: "name",
    rowSpan: 2,
  },
  {
    Header: languages.EN.labels.includedContent,
    accessor: "content",
    Cell: (data) => data.row.original.type === 0 ? (
      <TextTruncated lineClamp={1} style={{ width: "800px" }}>
        <Text>{getContentText(data.row.original)}</Text>
        <br />
        <br />
        {data.row.original.courses
          .map(course => (
            <div key={course.id}>
              <Text w800>{course.handle}</Text>: {course.display_name}
            </div>
          ))}
      </TextTruncated>
    ) : languages.EN.placeholders.empty,
    rowSpan: 2,
    cellStyle: { width: "170px" },
  },
  {
    Header: languages.EN.labels.expiryDate,
    accessor: (data) => dateFormat(data.expiry_date),
    rowSpan: 2,
  },
  {
    Header: languages.EN.labels.assignedEmployee,
    disableSortBy: true,
    headerStyle: { justifyContent: "center" },
    columns: [
      {
        Header: languages.EN.labels.name,
        accessor: "employeeName",
      },
      {
        Header: languages.EN.labels.email,
        accessor: "employeeEmail",
      },
      {
        Header: languages.EN.labels.jobPosition,
        accessor: "employeeJobPosition",
      },
    ],
  },
  {
    Header: languages.EN.labels.assignedOn,
    accessor: (data) => data.assigned ? dateFormat(data.assigned) : languages.EN.placeholders.empty,
    rowSpan: 2,
  },
  {
    Header: languages.EN.labels.lastAcitivity,
    accessor: (data) => data.last_activity ? dateFormat(data.last_activity) : languages.EN.placeholders.empty,
    rowSpan: 2,
  },
  {
    Header: languages.EN.labels.employeeStatisticsInCourses,
    disableSortBy: true,
    headerStyle: { justifyContent: "center" },
    columns: [
      {
        Header: languages.EN.labels.completed,
        accessor: (data) => data.user_completed !== null ? data.user_completed : languages.EN.placeholders.empty,
      },
      {
        Header: languages.EN.labels.inProgress,
        accessor: (data) => data.user_active !== null  ? data.user_active : languages.EN.placeholders.empty,
      },
      {
        Header: languages.EN.labels.remainningInIdp,
        accessor: (data) => data.user_remaining !== null  ? data.user_remaining : languages.EN.placeholders.empty,
      },
      {
        Header: languages.EN.labels.totalTimeSpent,
        accessor: (data) => data.total_time !== null  ? data.total_time : languages.EN.placeholders.empty,
      },
    ],
  },
];

const assignPopupFilterDefinitions = [
  {
    keyPath: ["user.profile.name", "user.profile.surname", "user.email"],
  },
  {
    keyPath: "user.profile.user_career_path.name",
    header: languages.EN.labels.careerPath,
    typeOfFilter: "checkbox",
  },
];

const PopupRenderItem = ({ item }) => {
  const user = item.user;
  const profile = user.profile;

  return (
    <Text s12 dark-4>
      {profile.name ? `${profile.name} ${profile.surname} (${user.email})` : user.email}
    </Text>
  );
}

const LicensesTable = ({ reports }) => {
  const { data, organizationId, collections, forPlatformOwner, filterInitialValue } = reports;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const { isPostFinish, isResponseOk, postResponse } = useContext(PostContext);
  const [assignedLicense, setAssignedLicense] = useState();

  const items = data.map((item) => {
    const courses = item.courses
      .concat(...item.collections.map(licenseCollection => collections.find(collection => collection.id === licenseCollection.id).courses))
      .filter((currentCourse, i, allCourses) => allCourses.findIndex((comparedCourse) => comparedCourse.handle === currentCourse.handle) === i)
      .sort((a, b) => a.handle.localeCompare(b.handle));

    return {
      ...item,
      employeeName: item.assigned_user === null || !item.assigned_user.profile.name ? languages.EN.placeholders.empty : `${item.assigned_user.profile.name} ${item.assigned_user.profile.surname}`,
      employeeEmail: item.assigned_user === null ? languages.EN.placeholders.empty : item.assigned_user.email,
      employeeJobPosition: item.assigned_user === null || !item.assigned_user.profile.user_job_position ? languages.EN.placeholders.empty : item.assigned_user.profile.user_job_position.name,
      courses: courses,
      content: getContentText(item),
    };
  });

  const fetchOrganizationMembers = useFetch();
  useEffect(() => {
    if (fetchOrganizationMembers.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: {
          header: languages.EN.labels.selectUser,
          items: fetchOrganizationMembers.data.map(item => ({
            ...item,
            id: item.user.username,
            label: item.user.profile.name ? `${item.user.profile.name} ${item.user.profile.surname} (${item.user.email})` : item.user.email,
          })),
          RenderItem: PopupRenderItem,
          filters: assignPopupFilterDefinitions,
          singleSelect: true,
          postAction: (formData) => licenseControlRepository.assignLicense(assignedLicense, { user: formData[0] }),
        },
      });
    }
  }, [fetchOrganizationMembers.isReady]);

  useEffect(() => {
    if (isPostFinish && isResponseOk === false && postResponse?.status) {
      callPopup({
        type: ACTIONS.INFORMATION,
        payload: {
          header: languages.EN.labels.error,
          content: postResponse.status,
        },
      });
    }
  }, [isPostFinish, isResponseOk, postResponse]);

  const fetchCourses = useFetch();
  useEffect(() => {
    if (fetchCourses.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.license}`,
          fields: (data) => licensePopupFields(collections, fetchCourses.data, data),
          postAction: (formData) => licenseControlRepository.addLicense({
            ...formData,
            organization: organizationId,
            price: formData.is_trial ? 0 : formData.price,
            collections: formData.type === '0' ? formData.content.filter(id => id.indexOf('collection_') === 0).map(id => id.replace('collection_', '')) : undefined,
            courses: formData.type === '0' ? formData.content.filter(id => id.indexOf('course_') === 0).map(id => id.replace('course_', '')) : undefined,
          }),
        },
      });
    }
  }, [fetchCourses.isReady]);

  const columns = useMemo(() => (forPlatformOwner ? platformOwnerColumns : administratorColumns).concat([
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      rowSpan: 2,
      Cell: (data) => {
        const expiresIn = moment(data.row.original.expiry_date).diff(moment(), "days");

        return (
          <div className={styles.actionBox}>
            {forPlatformOwner && (expiresIn < 0 || data.row.original.assigned_user === null) ? (
              <Button
                variant="iconButton"
                onlyIcon
                icon="garbage"
                onClick={() => callPopup({
                  type: ACTIONS.DELETE,
                  payload: {
                    type: languages.EN.labels.license,
                    elementName: data.row.original[forPlatformOwner ? 'number' : 'name'],
                    action: () => organizationLicensesRepository.deleteLicense(data.row.original.id),
                  },
                })}
                tooltip={languages.EN.labels.deleteLicense}
              />
            ) : null}
            {data.row.original.assigned_user !== null ? (
              <Button
                variant="iconButton"
                onlyIcon
                icon="cancel"
                onClick={() => callPopup({
                  type: ACTIONS.POST_CONFIRM,
                  payload: {
                    header: languages.EN.labels.unassignLicence,
                    content: <>
                      <Text s12 w600 lh14 dark-4>
                        {languages.EN.messages.areYouSureYouWantToUnassign}
                      </Text>
                      <Text s12 w800 lh14 dark-4>
                        {` ${data.row.original[forPlatformOwner ? 'number' : 'name']} `}
                      </Text>
                      <Text s12 w600 lh14 dark-4>
                        {languages.EN.labels.from.toLowerCase()}
                      </Text>
                      <Text s12 w800 lh14 dark-4>{` ${data.row.original.employeeName !== languages.EN.placeholders.empty ? data.row.original.employeeName : data.row.original.employeeEmail}?`}</Text>
                    </>,
                    action: () => organizationLicensesRepository.unassignLicense(data.row.original.id, data.row.original.assigned_user.username),
                  },
                })}
                tooltip={languages.EN.labels.unassignLicence}
              />
            ) : null}
            {forPlatformOwner && data.row.original.assigned_user === null ? (
              <Button
                variant="iconButton"
                onlyIcon
                icon="plus"
                onClick={() => callPopup({
                  type: ACTIONS.SEARCH_BACKEND_USERS,
                  payload: {
                    header: `${languages.EN.labels.selectUser}`,
                    singleSelect: true,
                    postAction: (formData) => licenseControlRepository.assignLicense(data.row.original.id, { user: formData[0] }),
                  },
                })}
                tooltip={languages.EN.labels.assignUser}
              />
            ) : null}
            {!forPlatformOwner && data.row.original.assigned_user === null ? (
              <Button
                variant="iconButton"
                onlyIcon
                icon="plus"
                onClick={() => {
                  setIsDataActive(false);
                  setAssignedLicense(data.row.original.id);
                  fetchOrganizationMembers.fetchData(organizationRepository.getMyOrganizationMembers());
                  callPopup({ type: ACTIONS.LOADING })
                }}
                tooltip={languages.EN.labels.assignUser}
              />
            ) : null}
            {data.row.original.assigned_user === null ? (
              <Button
                variant="iconButton"
                onlyIcon
                icon="send"
                onClick={() => callPopup({
                  type: ACTIONS.FORM,
                  payload: {
                    header: languages.EN.labels.inviteNewUser,
                    fields: newUserPopupFields(data.row.original, forPlatformOwner),
                    postAction: (formData) => licenseControlRepository.inviteUser({license_id: data.row.original.id, organization: organizationId, ...formData}),
                  },
                })}
                tooltip={languages.EN.labels.inviteNewUser}
              />
            ) : null}
          </div>
        )
      },
    },
  ]), []);

  return <Table buttons={forPlatformOwner ? [
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => {
        setIsDataActive(false);
        fetchCourses.fetchData(coursesRepository.getCourses());
        callPopup({ type: ACTIONS.LOADING })
      },
    },
  ] : undefined} columns={columns} data={items} filterInitialValue={filterInitialValue} getRowProps={(row) => {
    const expiresIn = moment(row.original.expiry_date).diff(moment(), "days");

    return {
      [ROW_VARIANT_ERROR]: expiresIn < 0,
      [ROW_VARIANT_WARNING]: expiresIn > 0 && expiresIn < 31,
    }
  }} />;
}

export default LicensesTable;
